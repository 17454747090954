- name: Dank Donkz
  artists:
    - Mister Häfftling
  upc: 5059366452706
  release: 2018-10-25
  cover: https://storage.googleapis.com/routenote-release-cloudfiles/5059366452706/dank_donkz1568992963.jpg
  link: https://push.fm/fl/dankdonkz
  tracks:
    - name: Dank Donkz
      isrc: GBSMU6919578
      length: 31
- name: Ich fresse euch!
  artists:
    - chrissx
  upc: 5059505935497
  release: 2018-12-13
  cover: https://storage.googleapis.com/routenote-release-cloudfiles/5059505935497/ich_fresse_euch1576791527.jpg
  tracks:
    - name: Ich fresse euch!
      isrc: GBSMU7345689
      length: 3:20
      lyrics: |
        [Intro: Trivia]
        Mich zu dissen ist eure letzte Chance, noch erfolgreich zu werden
        Doch so langsam bricht euer Lügenkonstrukt gleich zu Scherben
        Denn hiernach weiß jeder was für Ratten das sind
        Ja, Viktor hat auf jede Freundschaft gepisst

        [Part 1: Roots Ⅰ]
        Ok, wo soll ich anfangen? 2014, ich war noch nicht auf YouTube vertreten
        Hatte keinen eigenen Laptop oder PC, es kam das Ende grundschulischer Wege
        Minecraft und nen Acer Laptop, aber ne ich kannt dich nich, doch dann bist du Hure am reden
        Wandertag 5c, ich hatt' mich seitm halben Jahr wegen MC mit Johnti angefreundet
        Dann kamst du angeleutet, hey ich bin vier Jahre neben dem gesessen
        Erzählst von PlayStation Minecraft, irgendwas mit End-Portalen
        Hattest an mich Fragen, zu Zeiten, wo ich nicht mal ein Smartphone besessen habe
        Dann zogst du nach Bayreuth, denn du warst ein Bastard der nur den Schein wollt
        Jotan holte dich in Skypekonfis, bei deinen ich in der Küche saß
        Und in ein 50€-Headset von 2’12 sprach
        So lernte ich ja auch Amin kenn‘, nimm dir an ihm mal n Beispiel du Kek!

        [Part 2: Roots Ⅱ]
        Erinnerst du dich noch an die Hamachizeiten?
        Als wir zu 4. den ganzen Abend an Minecraftwelten feilten!
        Dann kam irgendwann dieser Kevin noch dazu
        Ich wusste von Anfang an: der is dochn noob!
        Irgendwann habe ich es dann auch gecheckt:
        Der Depp war vier Jahre in der Nachbarklasse…oh leck!
        Dann hab ich langsam mit Jontan fast nix mehr gemacht
        Denn ich hab die ganze freie Zeit mit euch Spasten verbracht
        Dann kam der Horni Beef
        Diggi dieser Piç hatte mein Vertrauen nie verdient
        Danach kam irgendwann RageRush
        Dabei machte ich dich Kek platt!
        
        [Part 3: Roots Ⅲ / Viktors Ende]
        Dazwischen kam Ich esse dich, ein monumentales Kunstwerk
        Meine Skills machten deine Lage ziemlich unfair!
        Wir alle fanden es lustig, das war nur ein Scherz am Rand
        Fühlte sich deine Pussy wohl auf einmal schmerzhaft an?
        Mitte 2017 - kontakt löschen und vorher blocken?
        Schon dafür würde dich ein anderer wohl boxen - zerbrochene knochen
        Monate später erfahr ich von kevin hast vergessen mich zu entblocken?
        Was seit ihr denn für Fotzen?
        In der Zwischenzeit kamen noch weitere KeKz-Disses
        Die Gegenangriffe waren im Wesentlichen Texte
        Die du bei Timatic und weiteren dieser Whack-MCs geklaut hast
        Dafür wirst du im Gegensatz gekaupert
        Dein Ende wird sehr lautstark!

        [Part 4: Ehre]
        Was laberst du Stricher, ich sei ein ehrenloser Pisser?
        Digga, ich kenn deine Mutter nich ma, du Wichser!
        Zeig ma die Memo, die irgendwer heimlich gemacht hat
        Kannst du nicht, denn du lügst - Bastard
        Aber dafür wirst du nutte auch gebumst
        Denn bevor ich deine mutter ne hure tauf‘, du hund
        Schneid ich mir die zunge aus dem mund!
        Aber ich kann sie dir zeigen, denn die gibt es
        Aber halt nur für leute mit ähnlichkeiten zu weekend!
        (ich hab so n kleines kind gefickt, ok?)
        …

        [Hook: Hallo Viktor]
        Hallo Viktor, ich fick dich jetzt ohne Punchlines
        Aber n paar könnt ich für dich noch unten dranreih’n!
        Hallo Viktor, mit Verrätern ist‘s nun endgültig aus
        Denn ich dreh nen Strick aus DDFK und häng euch dran auf!
        Hallo Viktor, lass das battln lieber sein
        Denn dafür bräuchtest du noch paar bessere lines!
        Hallo Viktor, geh mal weiter Black Ops zocken
        Mit Kevin, David und den andern Fotzen!
- name: HBL 2
  artists:
    - chrissx
  upc: 5059366199700
  release: 2019-01-02
  cover: https://storage.googleapis.com/routenote-release-cloudfiles/5059366199700/hbl21567547102.jpg
  link: https://push.fm/fl/hbl2
  tracks:
    - name: Qualifikation
      isrc: GBSMU6890568
      length: 1:17
      lyrics: |
        (Mister Häfftling Productions)

        [Intro]
        (ja ich hab immer noch bisschen Halsschmerzen und alles
        und das voll scheiße und ich bin immer noch gefickt
        und ja und die Quali is voll scheiße jetz
        aber das passt ja wenigstens zum Turnier)

        [Part]
        Was is das Turnier denn für ein Scheiß
        Eure Qualis, ja, die sind der Scheiß
        Also kurzgesagt beschissen
        Keiner von euch hat je was gerissen
        Wie ihr die macht fragt man sich: was hat die denn geritten
        Und ich sag euch: ne bitch war es nicht
        Ach übrigens: wer bezahlt euch bitches die klicks
        Wenn ich battle gibt's wie bei Dima Glied tief in dein Arschloch
        Du gibst das Programm an doch erzeugst schon 100 preprocessorerrors
        Um mich zu killen bräuchtest du acht Arme - Oktopus
        Das merkt man daran, dass du stotterst und
        Dass du große Augen machst - Mangakunst
        Deine Ma wird von Polen oft gebangt
        Deine Flöte wird oft geblasen? ich glaub, du meinst das Holzblasinstrument
        Jeopardy - chrissx - „Wer hat den besten Rap?“
        Und wenn ich Bock auf Web hab geh ich auf MRS
    - name: Viertelfinale vs. TrüffelArmy
      isrc: GBSMU6890571
      length: 1:19
      lyrics: |
        [Intro]
        (Leute TrüffelArmy hat mich zu nem Rapbattle herausgefordert)
        (Dann legen wir mal gleich los)
        (Hier)
        (Kommt das)
        (Hier kommt ähm die 4tel final runde für das Turnier des Rapbattles)
        (Der Trüffelschwein-Rap)

        [Part]
        Ich dachte, du bringst übel Lines
        Doch du müdes Trüffelschwein
        Klingst als ob du grade weinst
        Ich find ja, das Battle ist zu leicht
        Denn du klingst wie ich mit zehn und wie ein Hurensohn zugleich
        Aber eine Frage: Warum reimt man „Leute“ auf „Beute“
        Du tötest Zahlen, wow bist ja nen super krasser Spast
        Der durch Tracks über Fortnite zeigt, dass er ne Behinderung hat
        Dein Leben ist schwer? Ja, ich weiß, du Spaten
        Nur ich muss es dir jetzt einfach sagen
        Digga, du wurdest von Syk geschlagen
        Man, von einem Typ, der nich mal „Metzker“ schreiben kann
        Schon mutig, zu denken, dass du den Besten schlagen kannst
        Aber warte ma‘ du Spast
        ich fick dich garnich so hart
        Meine ganze Runde ist nur ein einziger Part

        [Outro]
        (ach ja und mein langsamer rapstyle is ne punchline)
    - name: Halbfinale vs. Scraw
      isrc: GBSMU6890573
      length: 1:12
      lyrics: |
        [Intro]
        (Ihr seid alle scheiße und ich nicht)

        [Part]
        Du sagst Google, er traut sich nicht, seine echte Stimme zu zeigen
        Doch in deinen Runden machst du dauernd das Gleiche
        Pitchen versteckt die Stimme auch, also ist dein Angriff bescheiden
        Salarsa, digga, wie kann man auf die scheiße Punchlinepunkte geben
        Kannste den denn überhaupt verstehen
        Wenn ja gib mir mal bitte nen paar Lyrics mit
        Denn nur wegen mir hat die Runde so viele Klicks
        Doch du wusstest das reicht nicht für den Rundenabschluss
        Also hast du bei Helli gelutscht und wurdest von Helli gepusht
        Schießmichtot, du heiliger Kauper
        Was der sich da mal erlaubt hat
        Dass er seinen Namen nach schwulen Videos gebaut hat

        [Outro]
        (Das waren grade die zwölf Bars mit denen du gefickt wurdest)
    - name: Finale vs. Verad
      artists:
        - chrissx
        - Mister Häfftling
      isrc: GBSMU6890575
      length: 1:33
      lyrics: |
        [Intro]
        (Jea n bisschen Heiserkeit das heißt ich trink deinen Kamillentee mal wieder)

        [Part 1: chrissx]
        Ertränke dich in deinem Kamillentee, den du ja so gern berappst
        Zieh mir danach erstmal ne Nase Pepp
        Ficke dich weg und schlage dir Bauer den Schädel ein
        Denn du klingst als würden deine Stimmbänder schon Tränen weinen
        Verad kein‘ Plan weswegen du immer „Nigger“ sagst
        Aber wie gefällt dir der hier, der is wirklich schwarz
        Kein Computergegner mehr und du piç fliegst
        Denn das beste an deinen Runden sind die beats
        So wies aussieht kannst du jeden Scheiß reden
        Ohne videos kann man ja auch nicht von den Lippen ablesen
        Obwohl ich dich nich versteh fick ich dich in Kopf
        So viele Ähnlichkeiten - warum nennst du dich nich Scraw
        Und zu Safari: Die Programmierfehler von diesem Spast
        Habe ich gemacht, damit du bitch es ins Finale schaffst
        Syk, die Entschuldigung für den Front nehm ich einfach einmal zurück
        Und sag du bist einfach ne bitch und nehm das keinmal zurück
        Ich schreibe einfach meinen halben Text in der Schule
        Während der langweiligen Physikstunde
        Und im Finale wirst jetzt du hässliche bitch
        Per Falcon Punch zurück ins Geffängnis geschickt

        [Part 2: Mister Häfftling]
        Du kommst ins Turnier und willst alle ficken
        Doch die Schwuchteln, die mit dir ficken, sind nur zu faul zum wichsen
        Doch komm ich in deine crackhöhle versteckst du dich schnell
        Bevor du aus Angst um dein Leben wegrennst
        Du vergisst das Trauma? - du kriegst es eh nich verdrängt
        Spritz dir Christian Meth - du bist querschnittgelähmt
        Es ist dieser Häußler, der die drugs pusht so wie Marcus
        Crassus während du für fame jeden schwanz lutscht
    - name: Kingfinale vs. Syk
      artists:
        - chrissx
        - Mister Häfftling
      isrc: GBSMU6890577
      length: 1:41
      lyrics: |
        [Intro: chrissx]
        (ok, ich glaub meine Stimme geht wieder)
        (wenn nich dann hatet mich nich)

        [Part: chrissx]
        Ich frag mich für wen du so nen Scheiß textest
        Bei der Bekanntheit deiner Features kannste auch in nen leeren Raum rappen
        Du featurest jeden um mich zu stoppen
        Doch jetz kann ich dich per Falcon Punch aus dem Finale umboxen
        Du lügst und hast angst vor dem battle, weil sich da zeigt
        Zu sagen, ich hätte gegen dich ne Chance, wäre echt die Wahrheit
        Denn wenn ich sag ich ficke dich piç
        Is das begründet, ich hab dich ja schon drei mal besiegt
        Du hast niemandem gezeigt wie man TrüffelArmy fickt
        Weil immer noch keiner auf die runde klickt
        Die Bewertungen beim Halbfinale zu faken, hilft dir auch nicht mehr weiter
        Verkackt, tja, Scheibenkleister
        Ich komm nicht mit „Häftlingscheiß“, sondern mit Häffti
        Wenn du sein‘ Namen nich schreiben kannst, geh mal weniger Meth zieh‘n
        Ach und wenn du Namen nich schreiben kannst
        Hast'e wohl selber eine am Rad
        Und baitest und du denkst, dass das die Fans begeistert
        Dabei ist das mit dir und Gios Rap nur eine Zweckgemeinschaft
        Du willst Safari deinstallieren? Schade, dass das nicht geht
        Du bist dumm? Für dich nochmal: hier das steht
        An verschiedensten Stellen sabotierst du das Turnier
        Erst sabotierst du bei meinen Gegnern dann sabotierst du bei mir
        Und hiernach bist du endgültig tot
        Denn du bist unnötig hinzugefügt, so wie das 10. Gebot

        [Hook: Mister Häfftling & chrissx]
        Häffti gib ma ne Hook
        Ok, ich zerteile den Spast
        Weil seine Whackness nich mal in nen Kleiderschrank passt
        Aber eigentlich suckt er bei jedem deiner Gegner
        Und zwang mich dazu. Was? Der Neger
- name: Random Rap Season Ⅰ
  artists:
    - chrissx
  upc: 5059594367582
  release: 2019-09-15
  cover: https://storage.googleapis.com/routenote-release-cloudfiles/5059594367582/randomrap_season_1_cover1597706775.jpg
  link: https://push.fm/fl/rrs1
  tracks:
    - name: Das CBT ist eröffnet!
      isrc: GBSMU8545917
      length: 1:03
      lyrics: |
        [Intro]
        (Das CBT ist eröffnet!)

        [Part]
        ZenaH ist Publikumsliebling mit seinem geilen Flow,
        ericenfermo disst sogar schon in der Qualifikation,
        Phoenix zerlegt im Projekt Flüsterton alle angekündigten Gegner
        Und bringt sie alle zum Saugen wie ein Staubsaugervertreter!
        InviZ - geiles Video wie ZenaH, er will's in diesem nicht zeigen,
        Doch er hat in seinem Text diverse wie vergleiche
        Bruno Bianco punktet mit Flow und musikalischer Dichtkunst
        Und beendet damit alles wie ein strichpunkt!
        Kronos kommt ins Turnier mit nem geilen Flow
        Und fast alle seine Punchlines sind dope
        In Scorpions Text klau ich bei Gio, weil ich ja einer von den Hartzern bin,
        Und mit seinem geilen Flow is des n klares Ding!
        Mit dem Oldschool hat aalex die meiste Ehre im Turnier,
        Wie soll er als Favorit das CBT denn noch verliern?

        [Outro]
        (Round one, fight!)
    - name: Das CBT geht ins Halbfinale!
      isrc: GBSMU8545918
      length: 42
      lyrics: |
        [Intro]
        (Das CBT geht ins Halbfinale!)

        [Part]
        Im 4tel hatte er wohl zu viel Angst zu verlieren,
        Also ist Scorpion gleich mal ausgestiegen.
        Somit ist Phoenix der einzige Gewinner im 4tel-Finale
        Doch Kronos hat wegen den Beatpicks die besseren Karten
        erics Trap trifft auf Brunos Laid Back Style,
        ZenaH und InviZ bleiben nur die Keks am mic,
        Die mit schlechten Lines jedem zeig'n,
        Dass sie eh nich lang im Turnier drin blei'm!

        [Outro]
        (Round two, fight!)
    - name: Der Vertreter - Die HR des Halbfinales
      isrc: GBSMU8545919
      length: 39
      lyrics: |
        Ob der Staubsaugervertreter schon mal ne Zena H Bitch geseh'n hat,
        Is egal, denn kronos verteilt Stiche wie ein Stichvertreter
        Doch Phoenix will ihn weiter seh'n,
        Also kommt er wieder auf neue Ideen,
        Weil in der Liste saugender Menschen alle Teilnehmer drin stehen.
        Doch der Stichvertreter kennt InviZ und die Zena H Bitch,
        Und schon zeigt es sich: Bis
        Bruno nicht mehr aufgibt gewinnt ein Kind
        Soll heißen: Im Battle gegen eric wäre er ältester,
        Doch Bruno steigt aus, nachdem er sich ne Woche nicht gemeldet hat
    - name: Das CBT geht ins Finale!
      isrc: GBSMU8545920
      length: 29
      lyrics: |
        Bruno hat kein' Bock mehr auf die Pischa
        Und Kronos meldet sich einfach nimma
        eric ist gegen Phoenix im Finale,
        Nur wer wird der Staubsaugervertreter und wer wird blasen?
        (lol)
    - name: Die RR des Finales
      isrc: GBSMU8545921
      length: 30
      lyrics: |
        Es is egal, ob InviZ schon mal den Phoenix geseh'n hat,
        Denn er hat trotz Saugen verkackt wie ein Staubsaugervertreter.
        Das heißt, das finale ist jetzt eric gegen Phoenix,
        Nur wer wird gewinnen und wer is so blöde,
        Zu verkacken gegen den andern Blödel?
        In der Hinrunde wollten sie ja nur spaßen,
        Also kommt nun die Rückrunde des Finales,
        Nur wer wird der Stichvertreter und wer wird sucken?
        (lel)
- name: Ich ersteche dich!
  artists:
    - chrissx
  upc: 5059505483271
  release: 2019-12-13
  cover: https://storage.googleapis.com/routenote-release-cloudfiles/5059505483271/ich_ersteche_dich1577230737.jpg
  tracks:
    - name: Ich ersteche dich!
      isrc: GBSMU7367940
      length: 2:12
      lyrics: |
        [Intro]
        Ich hab im HBL 2 und 3 diverse gegner zerfleischt
        Doch es wird wieder Zeit für KeKz diss zwei mal zwei
        Mit neuen Lines und ohne private Details
        Denn diesmal fick ich dich wieder nur leicht
        Doch ich hab dich verdaut und ausgekackt
        Wieder neu verpackt und den KeKzjunkies vorbeigebracht
        Nur was fängt da an, sich so zu regen
        Ey viktor, du bist ja immer noch am leben

        [Hook]
        Hallo Viktor, ich fick dich jetzt wieder mit Punchlines
        Die paar werde ich für dich noch unten dranreih’n
        Hallo Viktor, mit KeKzen ist‘s nun endgültig aus
        Denn ich kacke dich aus und knips dir das Licht aus
        Hallo Viktor, lass das betteln lieber sein
        Denn ich stech dich trotzdem noch zu Brei
        Hallo Viktor, geh mal weiter Fortnite zocken
        Mit Kevin, David und den andern Fotzen

        [Part]
        Ich hab keine zeit, denn ich schreibe schon wieder am Horni Diss
        Egal, schau, wie dich jetzt diese Torte fickt
        Spaß, du fühlst dich verarscht? komm
        Dieser Spast is mir mittlerweile so egal wie Jakob
        Ja man, ied hat sich verändert
        Doch schau auf die Haare, ich mach Jahre später immer noch dasselbe - wie Tastaturhersteller
        Viktor, eigentlich hab ich gar kein bock, dich zu dissen
        Ich würde dich viel lieber noch ficken
        Wie komm ich auf solche Zeilen, man, schreib ich des da rein
        Oder lass ich es vielleicht doch lieber sein
        Oh, war mir doch klar, dass das dich trifft
        Warum schreib ich das? ich bin müde, das is sowas wie bekifft
        Die anderen zwei Jahre hatten Kapitel nach Kapitel
        Doch diesmal reicht es, wenn ich dich einfach nur ficke
        Viktor hat dieses Battle schon lange verloren
        Da war er noch gar nicht mal geboren

        [Hook]
        Hallo Horni, ich fick Viktor jetzt wieder mit Punchlines
        Die paar Leichen werde ich noch unten dranreih’n
        Hallo Jakob, mit KeKzen ist‘s nun endgültig aus
        Denn ich kacke ihn aus und knips ihm das Licht aus
        Hallo Breze, Viktor lässt das betteln lieber sein
        Denn ich steche ihn trotzdem noch zu Brei
        Hallo Lisa, Viktor geht mal weiter Fortnite zocken
        Mit Kevin, David und den andern Fotzen
- name: Sachsen
  artists:
    - chrissx
  upc: 5059505898976
  release: 2019-12-23
  cover: https://storage.googleapis.com/routenote-release-cloudfiles/5059505898976/sachsen1591974232.jpg
  link: https://push.fm/fl/sachsen
  tracks:
    - name: Sachsen
      isrc: GBSMU8217810
      length: 1:37
      lyrics: |
        [Intro]
        In der Vergangenheit wie in der Gegenwart
        Hatten Sachsen immer mit Mobbing und Ausgrenzung zu kämpfen
        Jetzt ist damit Schluss

        [Part]
        Ständig wird vermutet und ständig wird getuschelt
        Was machen nur Menschen, die so an Dialekt dahernuscheln
        Sieht man sie mal einkaufen, erfährt man nämlich nicht viel
        Außer was man schon weiß: Die ham ja goakan Stil
        Der ihr Internet is a no' ned so ganz auf'm neusten Stand
        Und es überrascht die ned, is mal wieder a Wald verbrannt
        Sie vermuten Gefühle meist zur völlig falschen Zeit
        Und reisen meistens auch nicht so weit
        Die Steckerleisten rauchen ab, die Aktien sind gekauft
        Wo niemand Sprüche über'n Kauper macht, während man ihn hier verstaut
        Fashionmarken kennt man nicht, es wird nie auf Discord geklickt
        Sprachmemos hört man nicht und Videos macht man mal ganz fix

        [Hook]
        Sehr viel Ehre für Sachsen
        Sie ham es sich verdient
        Sehr viel Ehre für Sachsen
        Wir haben sie doch lieb
        Sehr viel Ehre für Sachsen
        Denn sie ham es sich verdient
        Sehr viel Ehre für Sachsen
        Wir haben sie doch alle lieb

        [Outro]
        An Silvester war ich in Sachsen
        Und da geh ich auch des Jahr wieder hi'
- name: Mukke aus der Versenkung Ⅰ
  artists:
    - chrissx
  upc: 5059504867546
  release: 2020-07-10
  cover: https://storage.googleapis.com/routenote-release-cloudfiles/5059504867546/madv_cover1594046953.jpg
  link: https://push.fm/fl/madv1
  tracks:
    - name: Intro aus der Versenkung
      isrc: GBSMU8339381
      length: 44
      lyrics: |
        [Intro]
        (Ich mach den Bypass rein wie ein Chefarzt)
        (Und bin fresh so wie der Dezember)

        [Part]
        Hey Leute und willkommen zu meiner ersten EP! (hi)
        (keine Ahnung, was der Typ da zieht)
        Halt Stop, nicht dieser Track! (mach aus)
        Selbst hierfür ist mein Freestyle zu großer Dreck
        Ich nehme fünf Tracks, die ich schon vor Ewigkeiten produziert hab
        Außer Mister Häfftling haben die auch keine Features
        Und dann mach ich noch zwei neue Tracks
        (Ludwig ist dreck?) Egal, jetz' mach das aus
        Aus Beats, die schon sehr hoch im Alter sind (alt)
        Manchmal neue Tracks und manchmal nur alter Shit

        [Outro]
        (Viel Spaß!)
    - name: Brett
      isrc: GBSMU8339382
      length: 2:07
      artists:
        - chrissx
        - Mister Häfftling
      lyrics: |
        [Part 1: chrissx]
        Was ist die Cypher denn für ein Scheiß
        Helli zu beeinflussen ist ziemlich leicht
        Man ich geb mir 'n paar Keta-Nasen
        Und mach aus der Sesamstraße ne Regenwarte
        Wer das noch leugnet muss wohl nen Fehler haben
        Oder hat beim Klonen von Reeperbahnen
        Ein paar Nebenstraßen fehlgewartet
        Ich geb mir Met und fahre in dein Dorf rein
        Gibst du dir mal Med, dann nur bei Fortnite
        Dieser Jiggy hat verkackt, aber ich bin sehr dope
        Zerlege den Ginger per Quellcode (Quellcode)
        Schau wie ich jetzt meine Rhymes spitt‘ und
        Zerlege die Einrichtung von Jiggys Seilspringschulen
        Kuchen ist nur mit drugs am Mic weil sein Vater Marcel Davis heißt
        Ich glaub, da pfeift mein Schwein und diverse andere Tiere
        Ich benutz' ihn nur, um meine Guns zu polieren

        [Hook: chrissx & Mister Häfftling]
        Wir zerlegen die Cypher komplett
        Man ihr seid alle whack und wir nicht
        Eure runden sind alle Dreck
        Dass ihr das checkt ist ziemlich wichtig
        Dont mess with me, Fucker
        Denn ich mach so viel Zaster
        Selbst deine Mama
        Suckt noch für 'n paar Dollars

        [Part 2: Mister Häfftling]
        Mister Häfftling is zurück und fickt endlich wieder alles
        Ey ich glaub, sogar Syk traut sich nich mehr, was zu sagen
        Pleuntah, du hattest im Prinzip ja recht
        Nur bist du der alman und ich fick deine Mutter, du Depp
        Und fangt ihr an schlechte Lines zu baiten
        Bin ich wie Lambsdorff, ich find das von der leyen heer/line her scheiße
        Rapper fühlen sich krass, denn sie bleiben bis 4 Uhr wach
        Ich liege meist noch um 6 wach im Bett und schreibe diesen part 4 Uhr nachts
        Du whacker rapper hast die punches in der rechten, du bastard
        Ich sag sowas nur wenn ich die Weidel zamschlag
        Sie wollen alle so sein wie ich, doch ihre Skills reichen nicht zum kopieren
        Nach diesem part kauft sich Gott Die Satanistische Bibel
        Darin liest er dann meine parts und sagt dann „man wie geil
        Häffti komm doch mal auf nen livegig vorbei“
        Aber du sagst, häffti braucht Texte mit Inhalt und aus diesem Grund
        Schlage ich jetzt das Brett vor meiner Stirn kaputt

        [Hook]
        Wir zerlegen die Cypher komplett
        Man ihr seid alle whack und wir nicht
        Eure Runden sind alle dreck
        Dass ihr das checkt ist ziemlich wichtig
    - name: Badlineflow
      isrc: GBSMU8339446
      length: 2:12
      lyrics: |
        [Part 1]
        Dieser Moment, wenn man ne Blockade hat
        Und einfach garnichts klappt
        Dieser Moment, wenn man sich im Bett langweilt
        Und an Reimen feilscht
        Und während du sparst mach ich mehr Para als dein Arzt
        YouTuber-Rapper haben Rap zerstört
        Ja, denn keiner bisher hat mich erhört

        [Hook]
        Ey, das ist badlineflow
        Alte lines sind nie dope
        Badlineflow
        But rest is only show

        [Part 2]
        Ich baller' wieder mit Barettas los
        Yeah Bretterflows/Bretterfloß
        Wie 'n selbstgebautes Rettungsboot und bretter' los
        Wie am Bretterfloß, dem selbstgebauten Rettungsboot
        Ich zerstöre euch bosshaft
        Während der Boss in Bosshaft sitzt
        Hier wo man über jeden Cop lacht
        Und sich jeder Sorgen um seinen Kopf macht

        [Hook]
        Ey, das ist badlineflow
        Alte lines sind nie dope
        Badlineflow
        But rest is only show

        [Part 3]
        Dieser Moment, wenn man im Bett liegt
        Und jeder Reim zieht
        Geht dieser Lauchmann
        Down gegen den Baum dann
        Geht auch mal der Baum ran
        Eine Frage: Warum reimt sich "SunDiego" auf "Punk? Ne, yo"
        Guter Reim: "Kollegah" auf "Flowfehler", aber Bro jeder Ko-Dreher hat Flowfehler, also bist du kein Totschläger

        [Hook]
        Ey, das ist badlineflow
        Alte lines sind nie dope
        Badlineflow
        But rest is only show

        [Part 4]
        Ey ich werd dich jetzt zertreten wie die Knete mit der du deine Pastete machst, doch da hab ich längst reingekackt
        Jetz' kommt n Hörnchen schnell vorbei
        Sagt allein, allein
        Mein Plan ist noch sehr geheim
        Danke Horni für den Reim
        Und dein TS Ist nicht dein TS, Sondern Danis TS
        Denn ein TS von dir Gleicht dem IS

        [Hook]
        Ey, das ist badlineflow
        Alte lines sind nie dope
        Badlineflow
        But rest is only show

        [Outro]
        Hab den Text rly in unter ner halben Stunde aus den schlechtesten alten lines zusammengeschissen
    - name: NBR Qualifikation
      isrc: GBSMU8339447
      length: 3:06
      artists:
        - chrissx
        - Mister Häfftling
      lyrics: |
        (Mister Häfftling Productions)
        (Mista Kemikka Filters)

        [Intro: chrissx]
        (Ok man des war um 3 Uhr nachts 8. August ich hab durchgemacht)
        (und neben FFmpeg Abfuck auch noch n bissl gecodet)

        [Part 1: chrissx]
        Ich suchte "rap battle" und was ich dann eben sah
        War so schrecklich, nach meinem aufschrei war's jedem klar
        Einen whacken Kingfinal-gegner
        Was ist das Turnier denn für ein Scheiß
        Zerlegen von den Lappen fällt mir ziemlich leicht
        Man kann die mit Abozahlen fronten, was soll'n das für Gegner sein
        Wie soll ich 'n Saftladen jetz' weiter dissen? da fällt mir wohl nix ein
        An Weihnachten spielst du den Nikolaus zuhaus
        Und in Internetvideos hast du die Jason Maske auf
        Denn du wirst von mir in den Boden gezogen
        Deine homes verbringen jeden Abend in Alkoholextasen
        Meine homes dealen mit Yu-Gi-Oh und Pokémon Karten
        Scheiß auf Selbstironie
        Ich bin der beste MC
        Gib mir einfach n beat
        Und ich mach geile Musik
        Weil Rapper nur an dicken beats nix verdienen
        Bringen sie noch schlichten beef mit ins Spiel
        Und was hab ich beim Texten im Bilde? Serbische Filme

        [Hook: chrissx & Mister Häfftling]
        Nikki das ist einfach nur ein scheiß Turnier
        Die Gegner kriegt man hier sehr leicht ausgespielt
        Du denkst aber das ist krass und du bist der Endgegner
        Und zeigst uns Eminem als deinen Darth Vader

        [Part 2: Mister Häfftling & chrissx]
        Bitch ich schreibe Reime und Verse
        Bei weitem nicht der Erste
        Doch einer der besten dieser Szene
        Denn ich ficke euch Fotzen auch in der Regel
        (also Inderinnen während der Regel? versteh i ned)
        Ich bin der Boss und steh nicht auf dem juice cover
        Weil ich regelmäßig deinem dad shore aufm kalten enzug klarmach
        Erwürge dich mit deiner Halskette, du Spaßbremse
        Bitch ich ficke deine Fotze immer weiter
        Bitch nenn es Tempelkriegszeitalter
        Denn du siehst mich ständig Arm drücken wie Apple-Mitarbeiter
        (moment Macs benutzen Intels also drücken die nicht immer ARMs - fock)
        Ich suche das turnier bei Google Chrome
        Und finde uhren, lol
        Ach da steht was macht ein Kutter wohl
        Ach ne dieser Nikki ist ein Hurensohn
        Ich hab wie Bandai ne dark soul
        Und bin am gegenteiltag low level - karol
        (kleiner bruder was heißt des)
        (robot karol is mehr high level als JavaScript)
        (scheiß nerd)

        [Hook: chrissx & Mister Häfftling]
        Nikki das ist einfach nur ein scheiß turnier
        Die Gegner kriegt man hier sehr leicht ausgespielt
        Du denkst aber das ist krass und du bist der Endgegner
        Und zeigst uns Eminem als deinen Darth Vader
        Nikki das ist einfach nur ein scheiß Turnier
        Die Gegner kriegt man hier sehr leicht ausgespielt
        Du denkst aber das ist krass und wird nicht gefickt
        Dann bist du nur ne dumme bitch

        [Outro: Mister Häfftling & chrissx]
        (OK der Text is von chris in circa ner Stunde entstanden)
        (kann ma was einfacheres verlangen)
        (ach ja circa drei Stunden später stand der beat dann auch)
    - name: PLB Qualifikation
      isrc: GBSMU8339448
      length: 1:28
      lyrics: |
        [Intro]
        Was is das Turnier denn für ein scheiß
        (Ok, das wird langsam langweilig)
        (häffti gib ma n richtig guten beat)
        (so einen wie für das hallo viktor 13.12. ding)
        (sowas)
        (Ja, genau)

        [Part]
        Der Typ, der wie viele die Fristen zu lang zieht
        Wird gefickt von dem Typen, der in jedem Turnier den Kack sieht
        Aber nich zu viel Arbeit, ich will wieder Minecraft zocken
        Aber wozu auch mehr als nen 16er droppen
        Aber worum dreht sich mein Leben? Ich zerficke euch Wackos
        Und dann geh ich paar Lines ziehen wie bei Bedwars
        Doch Ich sammel nachts zwischen zocken und codeteil‘n noch lines in meim mobile
        Und dann kriegst du nen Punch in dein hirn wie bei kampfsportturniern
        Klar, dass 99% der beschissenen Szene
        Schwer zu handlen sind wie von den lippen ablesen
        Und weil sie jetz mit dir Bastard done is
        Nehm ich die komplette Szene/Zähne hoch wie die Putze in ner Zahnarztpraxis
        Und um ans ende zu kommen, wird sinnloser text verpackt in floskeln:
        Denn Hast du keine lehre genossen, wird dir die ehre genommen
        Doch hast du lehre genossen, wirst du mit ehre übergossen
        Bis auf Zähne und Knochen, bis zu ner fähre geflossen (ah)
    - name: SM66 Qualifikation
      isrc: GBSMU8339449
      length: 1:45
      lyrics: |
        [Hook]
        Ich glaub ich hab gemerkt
        Dieses turnier ist so scheiße
        Doch ich hab es entfernt
        Is halt immer das gleiche

        [Part]
        Es ist part eins und ich durchdring schon alles wie ein dartpfeil
        Grad weil es auch der einzige in dieser quali ist
        Man ich nehme grad die ehre von einer bitch, denn eigentlich
        Ist dieses turnier aus professioneller sicht gesehn
        Organisatorisch nich mal mittelmäßig
        Doch ich zerteile es mit
        Musikalischen experimenten und du fragst dich, was das eigentlich ist
        Doch ich öffne fl studio, drücke alt und 1
        Und schon zeigt es sich, weil
        Zwischen dem noise hört man ein
        (i'm born to be wiiiiild)
        Ok, wtf ist dieser track eigentlich
        Denn ich rap gleichzeitig über das turnier
        Und meine bisher nur privaten projekte
        Das darf man ja nich vergessen
        Doch jetz ist die quali auch schon wieder vorbei
        Mit den schlechten lines und dem krassen style

        [Hook]
        Ich glaub ich hab gemerkt
        Dieses turnier ist so scheiße
        Doch ich hab es entfernt
        Is halt immer das gleiche
    - name: Randombeef
      isrc: GBSMU8339471
      length: 2:11
      lyrics: |
        [Intro]
        Da hat der Irre aus Pegnitz mal wieder gezeigt
        Dass Kritik an Parteien wohl mal Beef schafft
        Daraufhin hat ihm der xx mal aufgezeigt
        Dass er nur betreibt die Afterwissenschaft

        [Part 1]
        Alter, das hört sich ja an wie bei Goethe
        Pass fai auf, dass ich dich ned gleich töte
        Allmächt, nein lass mich am Leben
        Dann versuch, was Besseres anzustreben
        Dabei weiß er nicht mal, wie man unreime Reime reimt
        Aber nun sind die Beiden wie neuvereint
        Paar Stunden zuvor war'n sie noch Todesfeind
        Danach ham sie sich geg'nseitig dumm angeleimt
        Aber dann sagte ich: unus ignis quis vir
        multum ex arbore et dixit: studium fuga meus Impedir
        Aber wer ist denn dieser Firefighter
        Ja der xx, der hat nämlich nur Arbeitskleider
        Aber Silberkarl, haste schon gesehen
        Wie meine Videos durch die Decke gehen
        Du mit deinen Videos mit 15 Klicks
        Du hast doch nen Karriereknicks

        [Hook]
        Ey jo, warum ham wir wieder Beef
        Weil du so schräg bist wie die Schrift in kursiv
        Schau dir mal Brand an, sein Schaden ist adaptiv
        Oh mann wie geht nochmal der Ablativ

        [Part 2]
        Christian, es soll sich ein großes schwarzes Loch auftun
        Wir sind hier nämlich nicht am Abendgymnasium
        Oh man, des Loch is ja schon voll, da explodier
        Ich lieber deinen PC du hirnloser Alkoholiker
        Silberkarl ist doch Alkoholiker, der hat zwar Hirn
        Aber ich kann doch ned ohne meinen Bildschirm
        Warum haben sie meinen PC zerstört
        Ich hab doch schon damit aufgehört
        Ich hab nämlich nix gelernt
        Des ist sogar A-
        Weil ich wegen meiner
        Niemals kriegen werde meine
        Alter, hör damit auf, so zu sein zu sein wie Goethe
        Und blas lieber weiter in deine Flöte
        Junge du hast es geschafft, dass ich dich töte
        Fuck, mir tut weh eine Klöte

        [Hook]
        Ey jo, warum ham wir wieder Beef
        Weil du so schräg bist wie die Schrift in kursiv
        Schau dir mal Brand an, sein Schaden ist adaptiv
        Oh mann wie geht nochmal der Ablativ

        [Outro]
        (Ja ne, aber ernsthaft mal)
        (Wieso machst du jetz eigentlich so n schlechten Text)
        (Der's doch gut)
- name: Planlos x Schlaflos
  artists:
    - chrissx
  upc: 5059594517383
  release: 2020-09-18
  cover: https://storage.googleapis.com/routenote-release-cloudfiles/5059594517383/cover_21598910333.jpg
  link: https://push.fm/fl/pxs1
  tracks:
    - name: 0BADINTRO
      isrc: GBSMU8619964
      length: 36
      lyrics: |
        [Intro]
        (97.666 BPM, RandomBeat 2 referenz iz da, dann können wir ja anfangen)
        (du droppst jetzt ein Album, aber leider kriegt es keiner mit)

        [Part]
        „Mukke aus der Versenkung“ war nur der Abfall der letzten Jahre
        Jetzt kommt Mukke, die könnte sogar neben Capital charten
        Ich nenn vielleicht keine Namen
        Aber früher ham sie mich gehatet, heute wollen sie blasen
        Der Rest der Szene ist nur noch weak, die
        Releasen ihre Mukke auf Freebeats
        Meine Mukke ist selfmade, ich brauch nie Teams
        Außer Mister/Mista Häfftling und Kemikka, chrissx Media for life
    - name: 1BROWIESO
      isrc: GBSMU8619965
      length: 1:46
      lyrics: |
        [Intro]
        (Ey ich bin so lost alter)
        (Ich bin so müde)
        (Ich bin gefühlt high alter)
        (What the fuck man)
        (Vielleicht bin ichs ja auch)

        [Hook]
        Du bist jetzt auch ein Rapper, aber Bro wieso
        Du tickst jetzt auch mit Kilos Koks, aber Bro wieso
        Man, du disst jetzt meine Bros, aber Bro wieso
        Du glaubst, mein Tape ist nicht dein Ende, aber Bro wieso

        [Part]
        Du sagst, du willst meine Homes attackieren
        Nenn mich Utopia, ich werd das schnell annullieren (39)
        Du hörst diesen Part und greifst nach dem Tempo
        Denn du bist ein Unterweltler so wie Temtempo
        Da liegt so ein Stein auf der Straße (Straße)
        Ich werfe und treffe den Beamer, wie in der 9. Klasse (Klasse)
        Wie der Ablativ geht? Ich passe (passe)
        Bin wieder im Studio und hau auf die Kacke (Kacke)
        Bro meine Hood bleibt immer Pegnitz (Pegnitz)
        Dreh ich Videos, dann regnets (regnets)
        Ihr wollt Filme? Ich dreh des (dreh des)
        chrissx, um den Weg hier zu ebnen

        [Bridge]
        Ich war immer schon krass, verdammt
        Bei euch geht es nur um Cash – Mastercard
        Deutscher Rap kriegt einen Uppercut
        Und MCs fürchten mich, allesamt

        [Hook]
        Du bist jetzt auch ein Rapper, aber Bro wieso
        Du tickst jetzt auch mit Kilos Koks, aber Bro wieso
        Man, du disst jetzt meine Bros, aber Bro wieso
        Du glaubst mein Tape ist nicht dein Ende, aber Bro wieso

        [Outro]
        (Schau ich halt irgendwie ne Shampooflasche an)
        (Und diese Shampooflasche äh ähm ja die die)
        (Die wellt sich so komisch hin und her ey)
        (Ey des is einfach zu wild)
    - name: 2PART
      isrc: GBSMU8619966
      length: 1:15
      lyrics: |
        Und ich droppe einen part, ja
        Ich droppe einen part, ja
        Droppe einen part, ja
        Droppe einen part, ja
        Droppe einen part, ja
        Droppe einen part, ja
        Droppe einen part, ja
        Droppe einen part, ja
        Ich droppe einen part, ja
        Droppe einen part, ja
        Droppe einen part, ja
        Droppe einen part, ja
        Ich droppe einen part, ja
        Droppe einen part, ja
        Droppe einen part, ja
        Droppe einen part, ja
    - name: 3COFFEExCHIPS
      isrc: GBSMU8619967
      length: 3:05
      lyrics: |
        [Part 1]
        Ja ich sitze in der Küche
        Und ich sippe einen Kaffee
        Ich glaub das ist heute schon der fünfte
        Mach kein auge zu, weil ich sonst schlafe
        Ja ich sitze hier und mache nichts
        Würd ich was machen
        Hätte ich ja mal gute Klicks
        Aber das mach ich nicht

        [Hook 1]
        Coffee
        Coffee
        Coffee
        Coffee
        Coffee
        Coffee
        Coffee
        Coffee
        Coffee
        Coffee
        Coffee
        Coffee
        Coffee
        Coffee
        Coffee
        Coffee

        [Part 2]
        (7% Handyakku wir gehen rein und wir bleiben drin)
        Ja ich sitze in der Küche
        Und ich ess ne Dose Pringles
        Ich glaub das ist die woche schon die fünfte
        Dabei könnts bei der Menge auch mal klingeln
        Ja ich sitze hier und mache nichts
        Würd ich was machen
        Hätte ich ja mal gute Klicks
        Aber das mach ich nicht

        [Hook 2]
        Chips
        Chips
        Chips
        Chips
        Chips
        Chips
        Chips
        Chips
        Chips
        Chips
        Chips
        Chips
        Chips
        Chips
        Chips
        Chips
    - name: 4YEAESISTCHRISSX
      isrc: GBSMU8619968
      length: 1:26
      lyrics: |
        [Intro]
        (schneller)

        [Part]
        Yea es is chrissx, ich sitz in der Küche
        Yea es is chrissx, ich ficke die bitches
        Yea es is chrissx, ich mach irgendwas
        Yea es is chrissx, ich freestyle nen part
        Yea es is chrissx, es ist Dienstag
        Yea es is chrissx, es ist 4 Uhr nachts
        Yea es is chrissx, dieser part hat kaum inhalt
        Yea es is chrissx, denn nicht das brauch ich grad
        Yea es is chrissx, denn inhalt is egal
        Yea es is chrissx, und jetz verkack ich grad
        Yea es is chrissx, ich brauch noch vier bars um den Text zu füllen
        Yea es is chrissx, also freestyle ich irgendnen Müll, denn
        Yea es is chrissx, bräuchte ich über zehn Minuten, g'hört es in Müll, denn
        Yea es is chrissx, Texte schreibt man hier sehr schnell
        Yea es is chrissx, und das war es man
        Yea es is chrissx, der das auch beenden kann
    - name: 5HIGHSKIT
      isrc: GBSMU8619969
      length: 5:43
    - name: 6LUDWIG
      isrc: GBSMU8619970
      length: 2:27
      lyrics: |
        [Intro]
        (äh wie ging der freestyle nochmal gleich)
        (äh weiß ich gar nich mehr genau)
        (äh moment wie ging der freestyle nochmal)
        (ähm)

        [Part 1]
        Yea es is Dienstag und ich baue nen beat
        Keine Ahnung, was der Typ da zieht
        Nämlich meine ich den Ludwig den Kek
        Der is absolut beschränkt
        What the fuck laber ich in das mic
        Ich laber nur irgendnen Scheiß
        Warum? Weil ich nich freestylen kann
        Was laber ich? Keine Ahnung man
        Aber is auch ok, Ludwig is dumm
        Das merkt man mal wieder an seiner Behinderung
        Aber is ok bassd scho
        Alles gut bassd scho
        Don't believe the ludwig bassd scho
        Der beat is fast zuende bassd scho
        Und ich beende das jetzt bassd scho
        (was war da für ne letzte line man)

        [Hook]
        Und ich mach noch ne hook, weil ich muss den beat ja nutzen
        Wenn ich ihn schon mal gut gemacht hab
        Warum reimt sich nichts und ich sing schlecht
        Ich weiß es doch selber nicht

        [Part 2]
        Und Ludwig ist wieder in der Lobby auf Summoner's Rift
        Weil er mal wieder auf keinen Gegner trifft
        Weil nämlich keiner gegen ihn zocken will
        Weil er lost is und das weiß jeder Trottel
        Aber keine Ahnung was ich hier rappe
        Ich freestyle einfach irgendwas ihr Deppen
        Hört das überhaupt jemals irgendwer
        Ich hoff es nich, das wär nämlich ziemlich verkehrt
        Aber ok was rapp ich jetz noch
        Lol das is voll super wie'n cock lol
        (hahaha-hahahahahahahahahahaha geil)
        Aber is egal, Ludwig is n Kek
        Das weiß auch jeder, Ludwig is Dreck
        Ja das kann man mal so unterschreiben
        (ja äh unterschreiben kann man des oder)
        (ich glaub man ja man kann das schon unterschreiben so glaub ich ja)
    - name: 7DRAP
      isrc: GBSMU8619971
      length: 1:04
      lyrics: |
        [Intro]
        (ok im Stehen recorden und keine adlibs mitmachen das wird schwierig)
        Deutschrap ist komplett totgefickt (da da da)

        [Part]
        Deutschrap ist komplett totgefickt (scurr scurr scurr)
        Deutschrap ist komplett kaputtgefickt (scurr scurr scurr)
        Deutschrap ist komplett totgefickt (bam bam bam)
        Ihr seid tot denn hier kommt chrissx Media
        Ihr seid tot denn hier kommt chrissx Media
        Ihr seid tot denn hier kommt chrissx Media
        Ihr seid tot denn hier kommt chrissx Media
        chrissx Media bricht euch die Beine (Beine)
        chrissx drippt in Seide (Seide)
        chrissx Media übernimmt das Marketing (Marketing)
        Weil in der szene nur Bastards sind (Bastards sind)
        Deutschrap ist komplett totgefickt (da da da)
        Deutschrap ist komplett totgefickt (scurr scurr scurr)
        Deutschrap ist komplett kaputtgefickt (scurr scurr scurr)
        Deutschrap ist komplett totgefickt (bam bam bam)

        [Outro]
        (bam bam bam bam)
        (bam bam bam)
    - name: 10SHAMPOOFLASCHESKIT
      isrc: GBSMU8620272
      length: 48
    - name: 11ericof(BROWIESO)
      isrc: GBSMU8620273
      length: 1:55
      lyrics: |
        [Intro]
        Ey ich bin so lost alter
        Ich bin so müde
        Ich bin gefühlt high alter
        What the fuck man

        [Hook]
        Du bist jetzt auch ein Rapper, aber Bro wieso
        Du tickst jetzt auch mit Kilos Koks, aber Bro wieso
        Man, du disst jetzt meine Bros, aber Bro wieso
        Du glaubst, mein Tape ist nicht dein Ende, aber Bro wieso

        [Part]
        Du sagst, du willst meine Homes attackieren
        Nenn mich Utopia, ich werd das schnell annullieren
        Du hörst diesen Part und greifst nach dem Tempo
        Denn du bist ein Unterweltler so wie Temtempo
        Da liegt so ein Stein auf der Straße (Straße)
        Ich werfe und treffe den Beamer, wie in der 9. Klasse (Klasse)
        Wie der Ablativ geht? Ich passe (passe)
        Bin wieder im Studio und hau auf die Kacke (Kacke)
        Bro meine Hood bleibt immer Pegnitz (Pegnitz)
        Dreh ich Videos, dann regnets (regnets)
        Ihr wollt Filme? Ich dreh des (dreh des)
        chrissx, um den Weg hier zu ebnen

        [Bridge]
        Ich war immer schon krass, verdammt
        Bei euch geht es nur um Cash – Mastercard
        Deutscher Rap kriegt einen Uppercut
        Und MCs fürchten mich, allesamt

        [Hook]
        Du bist jetzt auch ein Rapper, aber Bro wieso
        Du tickst jetzt auch mit Kilos Koks, aber Bro wieso
        Man, du disst jetzt meine Bros, aber Bro wieso
        Du glaubst mein Tape ist nicht dein Ende, aber Bro wieso

        [Outro]
        Bro wieso
        Br-br-bro wieso
        Wieso
        Dr-dreh ich Videos dann regnets
        Ich bin gefühlt high alter, what the fuck man
    - name: 12ericof(PART)
      isrc: GBSMU8620274
      length: 1:12
      lyrics: |
        Und ich droppe einen part, ja
        Ich droppe einen part, ja
        Droppe einen part, ja
        Droppe einen part, ja
        Droppe einen part, ja
        Droppe einen part, ja
        Droppe einen part, ja
        Droppe einen part, ja
        Ich droppe einen part, ja
        Droppe einen part, ja
        Droppe einen part, ja
        Droppe einen part, ja
        Ich droppe einen part, ja
        Droppe einen part, ja
        Droppe einen part, ja
        Droppe einen part, ja
    - name: 13ericof(COFFEExCHIPS)
      isrc: GBSMU8620275
      length: 3:05
      lyrics: |
        [Part 1]
        Ja ich sitze in der Küche
        Und ich sippe einen Kaffee
        Ich glaub das ist heute schon der fünfte
        Mach kein auge zu, weil ich sonst schlafe
        Ja ich sitze hier und mache nichts
        Würd ich was machen
        Hätte ich ja mal gute Klicks
        Aber das mach ich nicht

        [Hook 1]
        Coffee
        Coffee
        Coffee
        Coffee
        Coffee
        Coffee
        Coffee
        Coffee
        Coffee
        Coffee
        Coffee
        Coffee
        Coffee
        Coffee
        Coffee
        Coffee

        [Part 2]
        Ja ich sitze in der Küche
        Und ich ess ne Dose Pringles
        Ich glaub das ist die woche schon die fünfte
        Dabei könnts bei der Menge auch mal klingeln
        Ja ich sitze hier und mache nichts
        Würd ich was machen
        Hätte ich ja mal gute Klicks
        Aber das mach ich nicht

        [Hook 2]
        Chips
        Chips
        Chips
        Chips
        Chips
        Chips
        Chips
        Chips
        Chips
        Chips
        Chips
        Chips
        Chips
        Chips
        Chips
        Chips
    - name: 14ericof(YEAESISTCHRISSX)
      isrc: GBSMU8620276
      length: 1:25
      lyrics: |
        [Intro]
        (yea es is ahahahahahahaha)
        (schneller)

        [Part]
        Yea es is chrissx, ich sitz in der Küche
        Yea es is chrissx, ich ficke die bitches
        Yea es is chrissx, ich mach irgendwas
        Yea es is chrissx, ich freestyle nen part
        Yea es is chrissx, es ist Dienstag
        Yea es is chrissx, es ist 4 Uhr nachts
        Yea es is chrissx, dieser part hat kaum inhalt
        Yea es is chrissx, denn nicht das brauch ich grad
        Yea es is chrissx, denn inhalt is egal
        Yea es is chrissx, und jetz verkack ich grad
        Yea es is chrissx, ich brauch noch 4 bars um den Text zu füllen
        Yea es is chrissx, also freestyle ich irgendnen Müll, denn
        Yea es is chrissx, bräuchte ich über 10 minuten, ghört es in Müll, denn
        Yea es is chrissx, Texte schreibt man hier sehr schnell
        Yea es is chrissx, und das war es man
        Yea es is chrissx, der das auch beenden kann
    - name: 15ZUCKERKAFFEESKIT
      isrc: GBSMU8620308
      length: 3:19
    - name: 16ericof(LUDWIG)
      isrc: GBSMU8620309
      length: 2:49
      lyrics: |
        [Intro]
        (äh wie ging der freestyle nochmal gleich)
        (äh weiß ich gar nich mehr genau)
        (äh moment wie ging der freestyle nochmal)

        [Part 1]
        Yea es is Dienstag und ich baue nen beat
        Keine Ahnung was der Typ da zieht
        Nämlich meine ich den Ludwig, den Kek
        Der is absolut beschränkt
        What the fuck laber ich in das mic
        Ich laber nur irgendnen Scheiß
        Warum? Weil ich nich freestylen kann
        Was laber ich? Keine Ahnung man
        Aber is auch ok, Ludwig is dumm
        Das merkt man mal wieder an seiner Behinderung
        Aber is ok bassd schon
        Alles gut bassd schon
        Dont believe the ludwig bassd schon
        Der beat is fast zuende bassd schon
        Und ich beende das jetzt bassd schon

        [Hook]
        Und ich mach noch ne Hook, weil ich muss den beat ja nutzen
        Wenn ich ihn schon mal gut gemacht hab
        Warum reimt sich nichts und ich sing schlecht
        Ich weiß es doch selber nicht
        Und ich mach noch ne Hook, weil ich muss den beat ja nutzen
        Wenn ich ihn schon mal gut gemacht hab
        Warum reimt sich nichts und ich sing schlecht
        Ich weiß es doch selber nicht

        [Part 2]
        Und Ludwig ist wieder in der Lobby auf Summoner's Rift
        Weil er mal wieder auf keinen Gegner trifft
        Weil nämlich keiner gegen ihn zocken will
        Weil er lost is und das weiß jeder Trottel
        Aber keine Ahnung was ich hier rappe
        Ich freestyle einfach irgendwas ihr Deppen
        Hört das überhaupt jemals irgendwer
        Ich hoff es nich, das wär nämlich ziemlich verkehrt
        Aber ok was rapp ich jetz noch
        Lol das is voll super wien cock lol

        [Hook]
        Und ich mach noch ne Hook, weil ich muss den beat ja nutzen
        Wenn ich ihn schon mal gut gemacht hab
        Warum reimt sich nichts und ich sing schlecht
        Ich weiß es doch selber nicht

        [Outro]
        Aber is egal, Ludwig is n kek
        Das weiß auch jeder, Ludwig is dreck
        Ja das kann man mal so unterschreiben
        (ja äh unterschreiben kann man des oder)
        (ich glaub man ja man kann das schon unterschreiben so glaub ich ja)
    - name: 17ericof(DRAP)
      isrc: GBSMU8620355
      length: 1:22
      lyrics: |
        Deutschrap ist komplett totgefickt
        Deutschrap ist komplett totgefickt
        Deutschrap ist komplett kaputtgefickt
        Deutschrap ist komplett totgefickt
        Ihr seid tot und hier kommt chrissx Media
        Ihr seid tot und hier kommt chrissx Media
        Ihr seid tot und hier kommt chrissx Media
        Ihr seid tot und hier kommt chrissx Media
        chrissx Media bricht euch die Beine (Beine)
        chrissx drippt in Seide (Seide)
        chrissx Media übernimmt das Marketing (Marketing)
        Weil in dieser Szene nur Bastards sind
        chrissx Media
        chrissx Media
        chrissx Media
        chrissx Media
        Deutschrap ist komplett totgefickt
        Deutschrap ist komplett totgefickt
        Deutschrap ist komplett kaputtgefickt
        Deutschrap ist komplett totgefickt
- name: chris x eric
  artists:
    - chrissx
    - depresja
  upc: 5059654538914
  release: 2020-10-23
  cover: https://storage.googleapis.com/routenote-release-cloudfiles/5059654538914/COVER_11601572679.JPG
  link: https://push.fm/fl/cxe1
  tracks:
    - name: questionmark
      isrc: GBSMU8807379
      length: 1:31
      lyrics: |
        [Intro]
        Ja ja, chrissx droppt fresh auf den Bea-
        (ja ja) Ja ja, chrissx droppt fresh auf den Bea-
        (ja ja) Ja ja, chrissx droppt fresh auf den Bea-
        (ja ja) Ja ja, chrissx droppt fresh auf den

        [Part 1]
        Ich bin zurück und Deutschrap zittert
        Weil meine Parts sind meist ein Punchlinegewitter
        Es ist halb 3, ich schreib Videoskripts
        Es ist 13 Uhr, ich mach wieder nix
        Weil ich schlaf den ganzen Tag
        Und in der Nacht schreib ich an Videos oder Parts
        So läuft mein Leben und du findest das traurig
        Aber das war doch mein Leben, glaub ich

        [Hook]
        chrissx macht wieder Mukkе
        chrissx macht wieder Mukke
        chrissx macht wieder Mukke
        chrissx macht wieder Mukke
        chrissx macht wieder Mukke
        chrissx macht wieder Mukke
        chrissx macht wieder neue Mukke
        Und die is so krass, des glaubt doch keiner

        [Part 2]
        Und ich droppe wieder krasse Bars
        Rolle über Deutschrap wie ein Laster, man
        Scheiß auf Savas, Sido und deine andern Stars
        Deutsche Rapper brauchen jetz' Bodyguards
        Denn mir is' egal, wie es den andern geht
        Muss wieder ins Studio, nen Part aufnehmen
        Heute is' mein Gig, live übertragen in der ARD
        Morgen is' Wahl und ganz Deutschland wählt die KPD
    - name: i turn on the heat
      isrc: GBSMU8807380
      length: 1:34
      lyrics: |
        [Intro]
        (No cap)
        Just call me Sweet, because I turn on the heat
        Transphobes still call me the sickest dude
        At rapping I am just a beast
        I am in the booth and I am in the mood
        Aber egal ich rap jetz auf nen beat
        Und das mach ich auch schon ziemlich gut
        Keine Ahnung was der Typ da zieht
        Es ist der Ludwig, der das tut

        [Hook]
        Just call me Sweet, because I turn on the heat
        Transphobes still call me the sickest dude
        At rapping I am just a beast
        I am in the booth and I am in the mood

        [Part]
        Ja ich ficke Deutschrap jetzt, denn die sind weak
        Und das wisst ihr auch schon ziemlich gut
        Dass ich sie entferne, dient
        Der Entfernung einer Behinderung
        Wegen Skills kommen alle zu mir
        Aber habt in euch ruhig Vertrauen, habt Mut
        Wir brauchen euch nicht, denn ihr
        Kollegah-Klone meint lange Reime wären genug

        [Hook]
        Just call me Sweet, because I turn on the heat
        Transphobes still call me the sickest dude
        At rapping I am just a beast
        I am in the booth and I am in the mood
        Just call me Sweet, because I turn on the heat
        Transphobes still call me the sickest dude
        At rapping I am just a beast
        I am in the booth and I am in the mood

        [Outro]
        (Just call me Sweet, because I turn on the heat)
        (Transphobes still call me the sickest dude)
        (At rapping I am just a beast)
        (I am in the booth and I am in the mood)
    - name: sees
      isrc: GBSMU8807381
      length: 43
      lyrics: |
        chrissx droppt jetz auf'n freshen beat rein
        Und ihr seid gefickt, tut mir ja ziemlich leid
        Aber egal, ich ficke euch trotzdem
        Hiernach wagt ihr‘s nicht mal mehr, ‘nen Part zu droppen
        Aber is ja auch egal
        Ich nehme euch hops, is ganz normal
        Mein shit übernimmt, dikka Instagram
        Ohne mich läuft nix, dikka Mittelsmann
        Ich hoffe nicht, dass die Bullen mein Twitter lesen
        Weil ich sonst bald locked up in nem Jail bin
        Ich öffne schnell Excel, um denen Aktеn zu nehmen
        Morgen geh ich in der Schule aufs Klo und piss daneben
        Sag zu Häffti, ich hab meine eigenen Tracks in der Playlist
        Und bin bald ne Celebrity so wie Akon
        Er sagt, er fährt morgen wieder um unseren Block
        Und ich glaub, der Teufel wohnt in meinem "cock lol"
    - name: funtasy_II
      isrc: GBSMU8807382
      length: 50
      lyrics: |
        chrissx am Mic, ich bin krass
        Schreibe alle meine Bars 3 Uhr nachts
        Drop dann gegen mittags die Parts
        Und bis Abend wird dann der Shit verpackt
        Nur am worken, man ich fuck nich mit hoes, ich
        Bin krasser equipt als dieser Daniel Zlotin
        Hoppe schnell ins car und wir ziehen los
        Ihr schiebt euch Filme und wir drehen Videos
        chrissx Media - in jedem Statement ist ein Asterisk
        Wenn wir Videos machen, ist da halt ein Asterix
        Diе halbe Szene hat nich mal halb so viel Skill wie ich
        Und macht damit dann die doppelten Klicks
        Ich würd' euch euern Film ja gerne abkaufen, aber geht nicht
        In etwa so als wärt ihr Netflix
        Eure Lines gehen links rein, aber immer wieder
        Kommen sie rechts raus, so wie SPD-Mitglieder
        (corona is da alter)
    - name: chrissxbeat
      isrc: GBSMU8807383
      length: 1:22
      lyrics: |
        [Part]
        Deine Rapper sind für mich nur whack
        Sie rappen über nichts and'res als cash
        Aber hier kommt der Chef
        Krasser Style, fickt alles und braucht nicht mal nen Reim
        Zeptah sieht mich auf Spotify
        Lyrium sieht einen Kommunisten auf nem 100 Dollarschein
        Und denkt sich: Wer könnte das denn sein
        Erinnert sich und drückt bei mir auf Like
        Es ist Donnerstag 12 Uhr, ihr seid lost so wie „reeezo“
        Ich hab grad Supreme bestellt und drip jetz in Peso
        Und ich bin sick, hab zehn Corona in mir
        Und ich bin dicht, hab zehn Corona in mir
        Warum ich jetz' mеine Bros diss
        Warum ich lines gegеn den Flo(w)-Kek kick
        Ich mach nich' n Selfie mit Handy vorm Gesicht
        Zu meinem Twitter Headpic

        [Hook]
        chrissx chrissx
        Beat beat beat beat
        chrissxbeat
        chrissxbeat
        chrissxbeat
        chrissxbeat
        chrissxbeat
        Ich fall auf, weil ich krass bin
        Deutschrap ist tot
        Ich hab ihn umgebracht
        Jetz bin ich endlich frei
    - name: interludefuerchris
      isrc: GBSMU8827806
      length: 45
    - name: back to school
      isrc: GBSMU8827807
      length: 1:20
      lyrics: |
        [Intro]
        (wo soll ich hin, digga, wo)

        [Part]
        Ich spring hoch und werd bekannt, denn es gibt nen Seebeben
        Und dann is es wie bei Cookies, denn es gibt nen kekz/sregen
        Es ist 6 Uhr morgens, ich bin wieder im Stu
        Mach nen neuen Track und der wird ziemlich gut
        Später geht's dann in die Schule und ich seh wieder die Gang
        Denn Schule heißt nicht nur, im Klassenzimmer zu penn
        Sondern auch, die Leute zu sehen, die man zwar kennt
        Und eigentlich auch gern hat, aber Monate nich gesehen hat, weil man an Corona denkt
        Und jetz' sitz' ich eben hier und schreibe nen part
        Deutschrapper wissen schon, der wird leider krass
        Aber schon gut, denn rapp ich nur über eure peinlichen parts
        Und bescheidenen stuff, bin ich ehrlich und sag: das reicht nich so ganz
        Deswegen dropp ich noch mehr bars über die Schule
        Die ich in den nächsten Wochen wohl wieder besuche
        Aber ne, die is whack, ich rapp' lieber darüber
        Wie es mich stresst, dass wir die ersten zwei Stunden in der Turnhalle haben

        [Outro]
        Wo soll ich hin, wo, wo soll ich hin
        Wo soll ich hin, wo soll ich nur hin
        Wo soll ich hin, wo, wo soll ich hin
        Wo soll ich hin, wo soll ich nur hin
    - name: randombeeftypexd
      isrc: GBSMU8827808
      length: 1:22
      lyrics: |
        [Intro]
        Es is 6 Uhr morgens, ich bin wieder im Stu
        Hab zwar wieder Schule, doch ich höre nie zu
        Mein Schlafrhythmus ist noch komischer als meine Videos
        Pack die Schultasche und dann zieh ich los
        (ha jea)
        (bruder was)

        [Part]
        Es ist 3 Uhr morgens und ich schreibe nen part (part)
        Auf nen beat, den hat eric für mich gemacht (macht)
        Den nehm ich dann auf vielleicht so gegen 6 (6)
        Aber dann bin ich auch schon wieder weg (weg)
        Zwei Stunden Geschichte, zwei Stunden Informatik
        Aber das ist mir jetzt еgal, weil ich bars kick
        Ja dieses jahr is so abgеfuckt, aber bald is schon Oktober (tober)
        Es is 4 uhr nachts und ich hör Sosa
        Ich wollte durchmachen, aber so gegen viertel 5 (5)
        Hab ich doch noch gepennt, was für ein Müll (Müll)
        Jetz ist es dreiviertel 7 und ich drippe zwar
        Aber das ist jetzt auch ziemlich egal
        Das LunaR T-Shirt, am hals die tfs chain
        Bei der Hose wären sogar Zebras neidisch, wenn die's sehen
        Ich trink n Monster, obwohl ich nicht mal richtig müde bin
        Heute nur vier Stunden Schule, glaub mir, dass das keine Lüge ist

        [Outro]
        (Ja das keine Lüge digga)
        (ye)
        (yea)
        (ye)
    - name: hotlinemiami_mittagspause
      isrc: GBSMU8827809
      length: 2:59
      lyrics: |
        Yea es is chrissx, ich hab zwei Freistunden
        Und auch noch Mittagspause, also genug Zeit um im
        Wiesweiher die halbe Kunsthausaufgabe
        Herunterzuladen

        War nur n joke ich mach die selber richtig krass
        Und in der Nähe der Schule schreib ich dann die parts
        Sag zu Sebi: du holst dir doch n Bier, mach mir auch eins klar
        Und er sagt, er holt sich keins da (keins da)

        Bro hab ich grad richtig gehört
        Sebi holt sich kein Bier? Ich war sehr empört
        Und übelst disturbed, denn ich fühlе das burnt
        Wenn sich n Bro ändert wegеn nem Verweis

        Das is standard sollte man meinen
        Denn ich bin immer noch der gleiche
        Aber für andere gilt das anscheinend nicht
        Dass das dem alten Sebi nicht peinlich ist

        Aber egal, so ändert der sich
        Ändert sich, wie in nem Veränderungsspiel
        Ändert sich wie der Stundenplan
        Und plötzlich ist es jetz nicht mehr der Weltuntergang

        Denn plötzlich hab ich die ersten vier Stunden frei
        Was für meine Schlafenszeit schon viel Gutes heißt
        Heißt irgendwann gegen 10 steh ich auf
        Und die ersten zwölf bars dieses tracks nehm ich auf

        Dann geh ich in die Schule, bisschen Deutsch, Englisch, Informatik
        Dachte, dass ich in der Mittagspause wieder paar bars kick
        Weil seit gestern weiß ich, das mag ich
        Aber so wieder mit Flo auf Monster Punch abchilled

        Anders wildes life, man ich schreibe nice lines
        Aber nicht mehr den Abend, denn ich hab leider keine Zeit
        Oder bin einfach zu müde, man ich weiß es nicht ganz
        Schule fickt dich am Ende halt so hart wie sie kann

        Mittlerweile brauch ich vier Wochen für nen Text
        Ja, so lange schreibe ich schon an diesem track
        Jetz komm ich nicht mehr täglich dazu, Rapper zu entehren
        Wenn Schule so weitergeht, frag ich mich, was soll aus mir noch werden

        Ich schreibe nur noch bis 1 oder 2 die parts
        Dann muss ichs lassen, brauch noch n bisschen Schlaf
        Und selbst dann bin ich frühs komplett zerstört
        Denn die Schule hat mein Leben zerstört

        chrissx is da und ich geh in die Schule
        Ja ich bin grad auf dem Weg in die Schule
        Und während dem freestyle ich noch nen krassen part
        Aber is ja auch komplett egal

        Ja ich freestyle auf nen krassen beat
        Den hat eric für mich produziert
        Ok, honestly kann ich nicht freestylen
        Wenn ich dabei noch anderen shit treibe

        Also eigentlich find ichs ja geil, zu freestylen
        Aber in der Mittagspause dann wohl leider nein
        Aber egal, ich werfe einen Stein
        Auf Miami Yacine, denn seine lines

        Sind der letzte Scheiß und sucken hart
        Und jucken niemanden mehr, so wie Laskah
        Und dieser Malte ist der letzte Arsch
        Denn er labert so stuff, man ich verstehe nich ganz

        Wie du kabha die drums von meinen parts nich so krass
        Finden kannst, man, ich rapp' krasse bars und du
        Machst whacken stuff und ich rappe halt krass
        Man du erreichst nie mein Level, du Spast

        Denn ich flex mit den parts so wahnsinnig krass
        Dass du an mein Level nicht kannst und stattdessen hatest du
        Doch ich bin next up und du zerstört so wie 2Bough
        (Ach und wer sagt, man hat keine freestyles auf seinem Album, kann sich ficken)
    - name: blarcox (blartox_x diss)
      isrc: GBSMU8827810
      length: 1:32
      lyrics: |
        [Part 1: chrissx]
        chrissx trägt Lipstick, geh mir weg mit Lipgloss (weg)
        Dieser Blartox sieht aus wie ein richtiger Pilzkopf (junge)
        Ich schau mir mal an, was du Prinzessin so forderst (mhm)
        Und merke: alles ergibt keinen Sinn bei dir, du Opfer (du Opfer)
        Willst, dass ich Englisch rede, obwohl du doch Deutsch kannst (lost)
        Das ist ein deutscher Server du Arsch (du Arsch)
        Ich hätte dich selbst mit Bogen noch easy gefickt (ja)
        Aber dafür reichte die Zeit dann wohl nicht (schade)

        [Part 2: depresja]
        Blarcox ist und bleibt 'n Pilzkopf
        Wie er rusht ist Cringe, jo
        Er ist am rushen und ist schеiße, man (jo)
        Aber wo sind seinе Coins, ich peils nich' ma (joa)
        Er sagt "english please", ich sag "halt's Maul, du Pferd" (ja)
        Sieh wie ich nen Bogen-Drive-By mach, während Chris das Auto fährt (brumm brumm)
        Kuck wie ich per Bogen diesen Kek grad' wegklatsch
        Er klatscht auf 'ne Brücke und is' weg vom Fenster (aua)
    - name: aufdentyler
      isrc: GBSMU8827811
      length: 1:34
      lyrics: |
        [Hook]
        Jajajaja
        chrissx droppt fresh auf
        Den Beat (jajaja)
        Den Beat (jajaja)
        Jajajaja
        chrissx droppt fresh auf
        Den Beat (jajaja)
        Den Beat (jajaja)

        [Part]
        Ess Fruitloops und öffne schon wieder FruityLoops
        Ich such noch immer noch dem Key für das Autotune
        Sie hängen gehypten Rappern an den Cocks
        Man, ihr könnt nur Faxen machen so wie Xerox
        Keine Proteinshakes, Kaffee is alles, was ich dir hier offern kann
        Ich fahr ohne Farid Bang zum CSD im goldenen Bobbycar
        Auf der Jagd nach InviZ, um den lyrisch zu vernichten
        Die Punches in der Rechten und mein Schicksal in der Linken
        Ich rapp' 1000 Bars, du hörst Stimm‘
        Es ist 3 Uhr nachts, ich hör Rin
        Ich deale mit Koka
        Sick wie Corona
        Ficke euch Opfer
        Delete Sosa, auf Wikipedia
        Gibt’s bald nix zu sehen, ja
        Versteht niemand Kidd, weil ich‘s so drehe, man

        [Hook]
        Jajajaja
        Chrissx droppt fresh auf
        Den Beat (jajaja)
        Den Beat (jajaja)
        Jajajaja
        Chrissx droppt fresh auf
        Den Beat (jajaja)
        Den Beat (jajaja)
    - name: randomrap2type
      isrc: GBSMU8827812
      length: 1:16
      lyrics: |
        [Intro]
        (ich mach den Bypass raus wie ein Chefarzt)
        (und bin ein Kek so wie der Typ der im Dezember kommt)

        [Part]
        Wozu noch Alben bring'? Man, ich hab gar keine Lust
        Planlos x Schlaflos hat doch schon alles gebummst
        (ich fuck deine crew)
        (ich schreib meist keine hooks)
        Mach in drei Wochen ein Album und es wird den Fans serviert
        Alle sind geflasht, weil kein besserer Rapper existiert
        Ich blick zurück auf den release und denk mir
        Mashallah mach doch nochmal
        Also drop ich n zweites album, weil ich erics beats so geil find
        Warum? Na, weil erics beats so geil sind
        Aber das nächste tape produzier ich wieder selber
        Weil es wär langweilig, nur als Rapper
        Denn ich generiere
        So krasse beats wie 7
        Bei dem geh ich noch immer so ab, ihr denkt, ich ziehe Pepp
        Ich machte zwei Alben und jetz' bin ich wieder weg
- name: Kurva
  artists:
    - chrissx
  upc: 5059654923543
  release: 2020-11-06
  cover: https://storage.googleapis.com/routenote-release-cloudfiles/5059654923543/kurva1604087451.jpg
  tracks:
    - name: Kurva - OG
      isrc: GBSMU8969187
      length: 53
      lyrics: |
        [Part 1: chrissx]
        Zdeněk je v prdeli
        Zdeněk je v prdeli
        Frederik je v prdeli
        Zdeněk je v prdeli
        Zdeněk je v prdeli
        Frederik je v prdeli

        [Hook: Frederik Albl]
        Kurva
        Kurva
        Kurva
        Kurva
        Ku-Ku-Ku-Ku-Ku-Kurva
        Kurva
        Kurva
        Kurva
    - name: Kurva - Extended
      isrc: GBSMU8969188
      length: 1:44
      lyrics: |
        [Part 1: chrissx]
        Zdeněk je v prdeli
        Zdeněk je v prdeli
        Frederik je v prdeli
        Zdeněk je v prdeli
        Zdeněk je v prdeli
        Frederik je v prdeli

        [Hook: Frederik Albl]
        Kurva
        Kurva
        Kurva
        Kurva
        Ku-Ku-Ku-Ku-Ku-Kurva
        Kurva
        Kurva
        Kurva

        [Part 2: chrissx]
        Je tu Pokémon
        Chtěl bych ho zničit, jo
        Sladký malý Weedle
        Ráda bych to šukat, jo

        [Hook: Frederik Albl]
        Kurva
        Kurva
        Kurva
        Kurva
        Kur-Kurva
        Kur-Kurva
        Ku-Ku-Kurva
        Kurva
    - name: Kurva - Remastered
      isrc: GBSMU8969189
      length: 1:44
      lyrics: |
        [Part 1: chrissx]
        Zdeněk je v prdeli
        Zdeněk je v prdeli
        Frederik je v prdeli
        Zdeněk je v prdeli
        Zdeněk je v prdeli
        Frederik je v prdeli

        [Hook: Frederik Albl]
        Kurva
        Kurva
        Kurva
        Kurva
        Ku-Ku-Ku-Ku-Ku-Kurva
        Kurva
        Kurva
        Kurva

        [Part 2: chrissx]
        Je tu Pokémon
        Chtěl bych ho zničit, jo
        Sladký malý Weedle
        Ráda bych to šukat, jo

        [Hook: Frederik Albl]
        Kurva
        Kurva
        Kurva
        Kurva
        Kur-Kurva
        Kur-Kurva
        Ku-Ku-Kurva
        Kurva
- name: Planlos x Schlaflos 2
  artists:
    - chrissx
  upc: 5059654996295
  release: 2020-12-04
  cover: https://storage.googleapis.com/routenote-release-cloudfiles/5059654996295/cover1606522593.jpg
  link: https://push.fm/fl/pxs2
  tracks:
    - name: 20GOODINTRO
      isrc: GBSMU9134823
      length: 1:29
      lyrics: |
        Immer wach und ich schlaf nich
        Immer wach und ich schlaf nich
        Immer wach und ich schlaf nich
        Immer wach und ich schlaf nich
        Ich hab keinen Plan was ich mache
        Ich hab keinen Plan was ich mache
        Aber ich schlaf auch nich
        Aber ich schlaf auch nich
        Ich mach ein Intro am Abgabetag
        Ich mach ein Intro am Abgabetag
        Deadline kickt rein, aber is mir egal
        Scur
    - name: 21IMBENZ
      isrc: GBSMU9134824
      length: 2:02
      lyrics: |
        [Hook]
        In meinem Benz
        Fahr ich nach Sachsen
        In meinem Benz (scur)
        Mach ich krasse Sachen
        In meinem Benz (scur)
        Fahr ich durch Rosenhof
        In meinem Benz (scur)
        Fahr ich die Opps tot (scur scur)

        [Part 1]
        Ich seh dich da hinten, du kleiner Bauer
        Du hast Angst, denn ich fick kleine Kinder wie der
        Ich geb dir einen Kick und du machst aua
        Mittagspause Rewe fick doch Kaufland
        Mein Ordner von l e i t z
        Fickt dich locker weg
        Du Kek
        (scur scur scur scur)
        (scur scur scur scur scur scur scur)
        Ich drop Bad Bars im Interview
        Denn das tut kleinen Kindern gut
        Ey yo die opps werden gekillt
        Dropp ich lines bist du am kochen wie ein Grill

        [Hook]
        In meinem Benz (scur)
        Fahr ich nach Sachsen
        In meinem Benz (scur)
        Mach ich krasse Sachen
        In meinem Benz (scur)
        Fahr ich durch Rosenhof
        In meinem Benz (scur)
        Fahr ich die Opps tot

        [Part 2]
        Du kriegst n Korb und hängst dich dann auf
        Wie bei nem Dunk, wow
        KC kann nich rappen, weil er grad meinen Dick kaut
        Ich steh ohne Hose vor der crowd wow
        Manchmal sind meine Texte quatsch
        Manchmal denk ich nach
        Was mein Text für ne message hat
        Heute nicht (scur scur scur)
        Wer kommt als erstes? Ich
        Wer kommt zu spät? Du
        Ihr wollt auf mein Level, doch ich fick euch
        Ihr müsst noch n bissl grinden so wie ich in SEUM

        [Hook]
        In meinem Benz (scur)
        Fahr ich nach Sachsen
        In meinem Benz (scur)
        Mach ich krasse Sachen
        In meinem Benz (scur)
        Fahr ich durch Rosenhof
        In meinem Benz (scur)
        Fahr ich die Opps tot (scur scur scur)

        [Outro]
        (scuur)
    - name: 22POLTERSTEIN
      isrc: GBSMU9134871
      length: 2:09
      lyrics: |
        [Hook]
        Ich bin drei Tage wach, Blut in den Augen
        Was für ein wundervoller Tag, um ein Monster zu saufen
        Wieder am Start, wieder ein neuer Stein
        Der trifft gut und wird schon recht teuer sein
        In meinem Visier AfD und Christian Lindner
        Also geh besser weg, du geldgeiler Pisser

        [Part]
        Se, chrissx und Florian gehn
        Jetz entspannt in sein BMW
        Und uns kann jeder sehn, wеil wir mega straight
        Auf AfD Wähler gehn und unsеr money zähln
        Ich bin mit der Gang unterwegs und es regnet
        Was willst du uns von deinem Drip denn erzählen
        Du machst das mit dem Autotune doch nur wegen deim label
        Ich kann Deutschrap parodiern, indem ich Dardan fake
        Oops, das war eine Punchline
        Ich entschuldige mich schonmal für den Drive-By
        In dem ich dein Scheiß kleinschneid
        Ich komm nie ins Game, weil ich schon ewig dabeibleib

        [Bridge]
        Du bist nur der Average Boi
        Und ich erfinde mich noch täglich neu
        Bezeichnest du mich als Dude in einem Minecraft-Chat
        Bin ich für dich mal wieder eine Weile weg
        Du bist nur der Average Boi
        Und ich erfinde mich noch täglich neu
        Bezeichnest du mich als Dude in einem Minecraft-Chat
        Bin ich für dich mal wieder eine Weile weg

        [Hook]
        Ich bin drei Tage wach, Blut in den Augen
        Was für ein wundervoller Tag, um ein Monster zu saufen
        Wieder am Start, wieder ein neuer Stein
        Der trifft gut und wird schon recht teuer sein
        In meinem visier AfD und Christian Lindner
        Also geh besser weg, du geldgeiler Pisser

        [Outro]
        Ich bin mit den Goons, wenn ich grad keine work hab
        Musikalisch gesehen bin ich ein Mörder
        Ja ich schiebe in deiner Stadt Turnup
        Und deine Gang kann's nicht mehr, weil ich sie zerstört hab
    - name: 23MILKACAKE
      isrc: GBSMU9134887
      length: 1:51
      lyrics: |
        [Intro]
        (scur)

        [Part 1]
        Yea ich bin chrissx, fang mal nicht an zu reden
        Und meine Snacks kaufe ich nur bei Mondelez
        Milka Minis Choco Cake, das zarte Milka Küchlein
        Im Mini-Format, für maximalen Genuss
        Hitze und Sonnenlicht können
        Zur Bildung eines harmlosen weißen
        Belags auf der Oberfläche der Schokolade führen
        (pause, pause, p-p-pause)

        [Hook]
        Yea Milka Cake in meinem belly
        Ich hab viel Ehre so wie levelyy
        Yea Milka Cake in meinem belly
        Ich hab viel Ehre so wie levelyy

        [Part 2]
        Du solltest mich besser nicht auf Twitter dissen
        Daily Milka Cake, Alkohol auf der Inhaltsliste
        Du hatest und deine homies finden mich gut
        Ich rocke All Black Everything - nenn mich Tim Cook
        Du foodest nicen stuff? ok bitte geh
        Das is niemals so gut wie Oreos oder Milka Cake
        Es ist Donnerstag und ich kauf mir Supreme
        Während du dein Geld für ein Auto ausgibst (du Opfer)

        [Hook]
        Yea Milka Cake in meinem belly
        Ich hab viel Ehre so wie levelyy
        Yea Milka Cake in meinem belly
        Ich hab viel Ehre so wie levelyy
    - name: 24MUT
      isrc: GBSMU9134888
      length: 1:33
      lyrics: |
        [Intro]
        Es ist Montag und ich zock Among Us mit der Gang
        Es ist Dienstag, ich zock Among Us mit der Gang
        Es ist Mittwoch und ich zock Among Us mit der Gang
        Es ist Donnerstag und ich zock Among Us mit der Gang
        Es ist Freitag und ich zock Among Us mit der Gang
        Es ist Samstag und ich zock Among Us mit der Gang
        Es ist Sonntag und ich zock Among Us mit der Gang
        Es ist 19 Uhr, ich zock Among Us mit der Gang

        [Part 1]
        Simon und ich sind Impostor, alle decken sich
        Se beschuldigt mich, wie soll ich das nur kontern?
        Und alle randomvoten, man was für Vollidioten
        Die würden doch safe auch die wires verknoten
        Wir verlieren wieder in Among Us, was für ein Drecksgame
        Aber trotzdem turnen wir morgen wieder up wiе ein Beyblade
        Flo fakеtaskt, wer hat Bock, sich sowas zu geben?
        Der Ludwig, denn der hockt mit der Stoppuhr daneben

        [Hook]
        Mut bidde, die Runde beginnt
        Alle zu killen, ist unser Instinkt
        Mut bidde, Ruhe jetzt
        Mut bidde, denn ich suche Keks

        [Part 2]
        In der nächsten Runde wird alles zerlegt
        Mit Se als Impostor Mate
        Wir sind direkt bereit um zu killen
        Denn YouTube machte es möglich, uns weiter zu bilden
        Dieser Flo will jetz ein BMW, doch hat er nur ein 3er
        Weil er gefickt wird von Chris Häußler und Sebastian Geier (gaaay)
        Jetz hat der Average Boi einen roten Fleck
        Und die Crewmates finden einen toten Kek

        [Hook]
        Mut bidde, die Runde beginnt
        Alle zu killen, ist unser Instinkt
        Mut bidde, Ruhe jetzt
        Mut bidde, denn ich suche Keks
    - name: 25NICESKIT
      isrc: GBSMU9134894
      length: 40
    - name: 26SIMON
      isrc: GBSMU9134906
      length: 1:43
      lyrics: |
        [Intro]
        (Simon)
        (Who Rain Zone)
        In Among Us schlechter als
        Ali, Luca, Robert, Flo, Silas, Se, Ludwig, erig
        Schlechter als Benedikt Mandel
        Schlechter als die ganze Loste Gang zusammen

        [Bridge]
        HCOOH - Wir lieben es alle
        HCOOH - Ameisensäure
        HCOOH - Simon eine zu knallen
        HCOOH - Und er fällt um wie im Sturm die Bäume

        [Hook]
        Und wir schmeißen seine Jacke
        Durch den ganzen Physiksaal
        Und endlich
        Leidet der Wichsеr

        [Part]
        Ich jag Simon Fleischer mit nem Zwiеbelschneider
        Kommt der Diertl vorbei, dann bringt er den Simon um
        Und kommt chrissx mit dem Basey rum
        Redet man von Simon nur noch im Präteritum
        Ey Simon, du bist echt leicht zu hassen
        Beim Dissschreiben hab ich Bock, auf deine Leiche zu kacken
        Denn was sagst du zu deinem Vorredner
        „Jonas raus“, du hörst dich ja an wie'n AfD'ler
        Und warum ich deine Mutter nicht ficke
        Sie ist deine Ma und du bist sogar zu dumm um zu trinken
        Da war der ganze Physikboden voll
        Ok, deine Ma squirtet ja toll
        Die Hure wurde jetzt auf Ehre getrocknet
        Ich geb dir Google Translate und sag du bist ne Wer Regen Zone
        Und wenn dir wieder sowas wie n fehlender Stift nicht passt
        Rennst du halt wieder ins Seki, du Spast

        [Bridge]
        HCOOH - Wir lieben es alle
        HCOOH - Ameisensäure
        HCOOH - Simon eine zu knallen
        HCOOH - Und er fällt um wie beim Sturm die Bäume

        [Hook]
        Und wir schmeißen seine Jacke
        Durch den ganzen Physiksaal
        Und endlich
        Leidet der Wichser
    - name: 27TURNUP
      isrc: GBSMU9134907
      length: 1:08
      lyrics: |
        [Intro]
        (Royal Rumble)

        [Part]
        chrissx hört 7DRAP (dam bam bam)
        chrissx hört 7DRAP (ja ja ja)
        chrissx hört 7DRAP (jo jo ja)
        chrissx hört 7DRAP (dam dam dam)
        chrissx schiebt Turnup (jaa)
        chrissx schiebt Turnup (ja ja ja)
        chrissx schiebt Turnup (ja ja ja)
        chrissx schiebt Turnup (bam bam bam)
        chrissx hört sieben Deutschrap Tracks
        chrissx hört sieben Deutschrap Tracks (whack)
        chrissx hört sieben Deutschrap Tracks (whack whack)
        chrissx hört sieben Deutschrap Tracks (whack whack whack)
        chrissx' Augen schieben Turnup (turnup)
        chrissx' Augen schieben Turnup (turnup)
        chrissx' Augen schieben Turnup (turnup)
        chrissx' Augen schieben Turnup (turnup)
    - name: 30DOWNSKIT
      isrc: GBSMU9134990
      length: 1:35
    - name: 31ericof(IMBENZ)
      isrc: GBSMU9134992
      length: 1:56
      lyrics: |
        [Hook]
        In meinem Benz
        Fahr ich nach Sachsen
        In meinem Benz
        Mach ich krasse Sachen
        In meinem Benz
        Fahr ich durch Rosenhof
        In meinem Benz
        Fahr ich die Opps tot (scur scur)

        [Part 1]
        Ich seh dich da hinten, du kleiner Bauer
        Du hast Angst, denn ich fick kleine Kinder wie der
        Ich geb dir einen Kick und du machst aua
        Mittagspause Rewe fick doch Kaufland
        Mein Ordner von l e i t z
        Fickt dich locker weg
        Du Kek
        (scur scur scur scur scur scur scur scur scur scur scur)
        Ich dropp Bad Bars im Interview
        Denn das tut kleinen Kindern gut
        Ey yo die opps werden gekillt
        Dropp ich lines bist du am kochen wie ein Grill

        [Hook]
        In meinem Benz
        Fahr ich nach Sachsen
        In meinem Benz
        Mach ich krasse Sachen
        In meinem Benz
        Fahr ich durch Rosenhof
        In meinem Benz
        Fahr ich die Opps tot

        [Part 2]
        KC kann nich rappen, weil er grad meinen Dick kaut
        Ich steh ohne Hose vor der crowd wow
        Manchmal sind meine Texte quatsch
        Manchmal denk ich nach
        Was mein text für ne message hat
        Heute nicht (scur scur scur)
        Wer kommt als erstes - ich
        Wer kommt zu spät - du
    - name: 32ericof(POLTERSTEIN)
      isrc: GBSMU9135049
      length: 4:16
      lyrics: |
        [Hook]
        Ich bin drei Tage wach, Blut in den Augen
        Was für ein wundervoller Tag, um ein Monster zu saufen
        Wieder am Start, wieder ein neuer Stein
        Der trifft gut und wird schon recht teuer sein
        In meinem visier AfD und Christian Lindner
        Also geh besser weg, du geldgeiler Pisser

        [Part]
        Se, chrissx und Florian gehn
        Jetz entspannt in sein BMW
        Und uns kann jeder sehn, wеil wir mega straight
        Auf AfD Wähler gehn und unsеr money zähln
        Ich bin mit der Gang unterwegs und es regnet
        Was willst du uns von deinem Drip denn erzählen
        Du machst das mit dem Autotune doch nur wegen deim label
        Ich kann Deutschrap parodiern, indem ich Dardan fake
        Oops, das war eine Punchline
        Ich entschuldige mich schonmal für den Drive-By
        In dem ich dein Scheiß kleinschneid
        Ich komm nie ins Game, weil ich schon ewig dabeibleib

        [Bridge]
        Du bist nur der average boi
        Und ich erfinde mich noch täglich neu
        Bezeichnest du mich als Dude in einem Minecraft-Chat
        Bin ich für dich mal wieder eine Weile weg
        Du bist nur der average boi
        Und ich erfinde mich noch täglich neu
        Bezeichnest du mich als Dude in einem Minecraft-Chat
        Bin ich für dich mal wieder eine Weile weg

        [Hook]
        Ich bin drei Tage wach, Blut in den Augen
        Was für ein wundervoller Tag, um ein Monster zu saufen
        Wieder am Start, wieder ein neuer Stein
        Der trifft gut und wird schon recht teuer sein
        In meinem visier AfD und Christian Lindner
        Also geh besser weg, du geldgeiler Pisser
        (äh)

        [Bridge]
        Ich bin mit den Goons, wenn ich grad keine work hab
        Musikalisch gesehen bin ich ein Mörder
        Ja ich schiebe in deiner Stadt Turnup
        Und deine Gang kann's nicht mehr, weil ich sie zerstört hab

        [Hook]
        Wieder am Start, wieder ein neuer Stein
        Der trifft gut und wird schon recht teuer sein
        In meinem Visier AfD und Christian Lindner
        Also geh besser weg, du geldgeiler Pisser
        Wieder am Start, wieder ein neuer Stein
        Der trifft gut und wird schon recht teuer sein
        In meinem Visier AfD und Christian Lindner
        Also geh besser weg, du geldgeiler Pisser

        [Part]
        Se, chrissx und Florian gehn
        Jetz entspannt in sein BMW
        Und uns kann jeder sehn, wеil wir mega straight
        Auf AfD Wähler gehn und unsеr money zähln
        Ich bin mit der Gang unterwegs und es regnet
        Was willst du uns von deinem Drip denn erzählen
        Du machst das mit dem Autotune doch nur wegen deim label
        Ich kann Deutschrap parodiern, indem ich Dardan fake
        Oops, das war eine Punchline
        Ich entschuldige mich schonmal für den Drive-By
        In dem ich dein Scheiß kleinschneid
        Ich komm nie ins Game, weil ich schon ewig dabeibleib

        [Bridge]
        Du bist nur der average boi
        Und ich erfinde mich noch täglich neu
        Bezeichnest du mich als Dude in einem Minecraft-Chat
        Bin ich für dich mal wieder eine Weile weg
        Du bist nur der average boi
        Und ich erfinde mich noch täglich neu
        Bezeichnest du mich als Dude in einem Minecraft-Chat
        Bin ich für dich mal wieder eine Weile weg

        [Outro]
        Ich bin drei Tage wach, Blut in den Augen
        Was für ein wundervoller Tag, um ein Monster zu saufen
        Wieder am Start, wieder ein neuer Stein
        Der trifft gut und wird schon recht teuer sein
        Ich bin drei Tage wach, Blut in den Augen
        Was für ein wundervoller Tag, um ein Monster zu saufen
        Wieder am Start, wieder ein neuer Stein
        Der trifft gut und wird schon recht teuer sein
    - name: 33ericof(MILKACAKE)
      isrc: GBSMU9135065
      length: 1:43
      lyrics: |
        [Part 1]
        Yea ich bin chrissx, fang mal nicht an zu reden
        Und meine Snacks kaufe ich nur bei Mondelez
        Milka Minis Choco Cake, das zarte Milka Küchlein
        Im mini-format, für maximalen Genuss
        Hitze und Sonnenlicht können
        Zur Bildung eines harmlosen weißen
        Belags auf der Oberfläche der Schokolade führen
        (mh, white sauce in meinem Mund)

        [Hook]
        Yea Milka Cake in meinem belly
        Ich hab viel Ehre so wie levelyy
        Yea Milka Cake in meinem belly
        Ich hab viel Ehre so wie levelyy

        [Part 2]
        Du solltest mich besser nicht auf Twitter dissen
        Daily Milka Cake, Alkohol auf der Inhaltsliste
        Du hatest und deine homies finden mich gut
        Ich rocke All Black Everything - nenn mich Tim Cook
        Du foodest nicen stuff? ok bitte geh
        Das is niemals so gut wie Oreos oder Milka Cake
        Es ist Donnerstag und ich kauf mir Supreme
        Während du dein Geld für ein Auto ausgibst
    - name: 34ericof(MUT)
      isrc: GBSMU9135078
      length: 1:37
      lyrics: |
        [Intro]
        Es ist Montag und ich zock Among Us mit der Gang
        Es ist Dienstag und ich zock Among Us mit der Gang
        Es ist Mittwoch und ich zock Among Us mit der Gang
        Es ist Donnerstag und ich zock Among Us mit der Gang
        Es ist Freitag und ich zock Among Us mit der Gang
        Es ist Samstag und ich zock Among Us mit der Gang
        Es ist Sonntag und ich zock Among Us mit der Gang
        Es ist 19 Uhr ich zock Among Us mit der Gang

        [Part 1]
        Simon und ich sind Impostor, alle decken sich
        Se beschuldigt mich, wie soll ich das nur kontern?
        Und alle randomvoten, man was für Vollidioten
        Die würden doch safe auch die wires verknoten
        Wir verlieren wieder in Among Us, was für ein Drecksgame
        Aber trotzdem turnen wir morgen wieder up wiе ein Beyblade
        Flo fakеtaskt, wer hat Bock, sich sowas zu geben?
        Der Ludwig, denn der hockt mit der Stoppuhr daneben

        [Hook]
        Mut bidde, die Runde beginnt
        Alle zu killen, ist unser Instinkt
        Mut bidde, Ruhe jetzt
        Mut bidde, denn ich suche Keks

        [Part 2]
        In der nächsten Runde wird alles zerlegt
        Mit Se als Impostor Mate
        Wir sind direkt bereit um zu killen
        Denn YouTube machte es möglich, uns weiter zu bilden
        Dieser Flo will jetz ein BMW, doch hat er nur ein 3er
        Weil er gefickt wird von Chris Häußler und Sebastian Geier
        Jetz hat der average boi einen roten Fleck
        Und die Crewmates finden einen toten Kek

        [Hook]
        Mut bidde, die Runde beginnt
        Alle zu killen, ist unser Instinkt
        Mut bidde, Ruhe jetzt
        Mut bidde, denn ich suche Keks
    - name: 35COLAKAFFEESKIT
      isrc: GBSMU9135083
      length: 1:43
    - name: 36ericof(SIMON)
      isrc: GBSMU9135088
      length: 1:56
      lyrics: |
        [Intro]
        HCOOH - Wir lieben es alle!
        HCOOH - Ameisensäure!
        HCOOH - Simon eine zu knallen!
        HCOOH - Und er fällt um wie beim Sturm die Bäume!
        Und wir schmeißen seine Jacke
        Durch den ganzen Physiksaal (durch den ganzen Physiksaal)
        Und endlich
        Leidet der Wichsеr
        (Simon)

        [Part]
        In Among Us schlechter als
        Ali, Luca, Robert, Flo, Silas, Se, Ludwig, erig
        Schlechter als Benedikt Mandel
        Schlechter als die ganze Loste Gang zusammen
        Ich jag Simon Fleischer mit nem Zwiеbelschneider
        Kommt der Diertl vorbei, dann bringt er den Simon um
        Und kommt chrissx mit nem Basey rum
        Redet man von Simon nur noch im Präteritum
        Ey Simon, du bist echt leicht zu hassen
        Beim Dissschreiben hab ich Bock, auf deine Leiche zu kacken
        Denn was sagst du zu deinem Vorredner
        "Jonas raus", du hörst dich ja echt an wie'n AfD'ler
        Warum ich deine Mutter nicht ficke
        Sie ist deine Ma und du bist sogar zu dumm um zu trinken
        Da war der ganze Physikboden voll
        Ok, deine Ma squirtet ja toll
        Die Hure wurde jetzt auf Ehre getrocknet
        Ich geb dir Google Translate und sag du bist ne Wer Regen Zone
        Und wenn dir wieder sowas wie ein fehlender Stift nicht passt
        Rennst du halt wieder ins Seki, du Spast

        [Outro]
        Und wir schmeißen seine Jacke
        Durch den ganzen Physiksaal
        Und endlich
        Leidet der Wichser
    - name: 37ericof(TURNUP)
      isrc: GBSMU9135089
      length: 1:35
- name: Ich entsorge dich!
  artists:
    - chrissx
  upc: 5059654347400
  release: 2020-12-13
  cover: https://storage.googleapis.com/routenote-release-cloudfiles/5059654347400/ich_entsorge_dich1608160477.jpg
  link: https://push.fm/fl/ied2020
  tracks:
    - name: Ich entsorge dich!
      isrc: GBSMU9238320
      length: 1:20
      lyrics: |
        [Part 1]
        Ey jetz wirst du entsorgt
        Du bist wie Jonas komisch born
        Und nervst auch wie dieser Horn
        Doch sei dir dessen bewusst
        Niemand wird dich vergessen, mit Grund

        [Hook 1]
        Ich ermorde dich
        Entsorge dich
        Mag Horni nicht
        Verordne dich

        [Part 2]
        Unter all den whackheads
        Die braucht man nicht mal kennen
        Um zu erkennen
        Dass die whack sind
        Doch ich bin wie Tastaturhersteller
        Denn ich hab mich nie geändert

        [Hook 1]
        Ich ermorde dich
        Entsorge dich
        Mag Horni nicht
        Verordne dich

        [Part 3]
        Unter all den whackheads
        Denn du bist endlich tot
        Ich such nen guten Ort
        Und vergrabe dich dann dort
        Denn auf einmal wird der kekzechef zum toten kek

        [Hook 2]
        Hallo Horni, dir war klar, dass das hier kommt
        Gegen Viktor war das jetzt der letzte Song
        Hallo Horni, du bist als nächstes dran
        Wie du an ZER ja schon sehen kannst
        Hallo Horni, Viktor liegt tot unter der Erde
        Als nächstes werde ich dein leben nehmen
        Hallo Horni, du hast jetzt schon Angst
        Na dann wart mal bis nächstes Jahr
- name: weihnachten 2020 lulw
  artists:
    - chrissx
  upc: 5059654285443
  release: 2020-12-24
  cover: https://storage.googleapis.com/routenote-release-cloudfiles/5059654285443/cover_weihnachten_20201608763684.jpg
  link: https://push.fm/fl/weihnachten2020lulw
  tracks:
    - name: weihnachten 2020 lulw
      isrc: GBSMU9277878
      length: 48
      lyrics: |
        [Intro]
        O Tannenbaum, o Tannenbaum
        O Tannenbaum, o Tannenbaum

        [Part]
        Jea, es is Weihnachten und ich baue nen beat
        Keine Ahnung was der Typ da zieht
        Nämlich meine ich den Weihnachtsmann
        Der stirbt, weil er nichts gegen uns beide kann
        chrissx Mister Häfftling ein tec team sondergleichen
        Bald kommt ein Track, um es euch zu beweisen
        Dass wir Kings im Game sind
        Dass wir Kings im Game sind
        Ok, da war bissl was falsch, aber egal
        chrissx rappt wieder zu krass, aber egal
        Weihnachten is wieder da, aber egal
        Christentum trash, aber egal
- name: Yin Krissi
  artists:
    - chrissx
  upc: 5059741097553
  release: 2021-02-26
  cover: https://storage.googleapis.com/routenote-release-cloudfiles/5059741097553/t4KbTb5Vy9_1612366094.jpg
  link: https://push.fm/fl/yinkrissi
  tracks:
    - name: Beats
      isrc: GBSMU9522536
      length: 1:28
      lyrics: |
        [Hook]
        chrissx baut die Beats, chrissx fickt dein ganzes Team (ja)
        chrissx baut die Beats, chrissx fickt dein ganzes Team (ja)
        chrissx baut die Beats, chrissx nimmt dein ganzes Team hops (hops)
        chrissx baut die Beats, Deutschrapper whack wie Crackrocks

        [Part]
        Schule unnötig und whack wie Popschutz
        Diss mich einmal, ich lauf in deiner Hood mit ner Glock rum (bam bam)
        Schule unnötig und whack wie Webcams
        All eure Skills sind nur second hand shit (shit)
        Ich mach krassen shit in unter ner Stunde (ja)
        Ich mach krassen shit in unter ner Stunde (ja)
        Ich mach krassen shit in unter ner Stunde (ja)
        Für die nächste krasse line brauch ich nicht mal ne Sekunde
        Andre rapper labern Scheiße und geben's nicht zu (na na)
        Aber ich rappe schon immer viel zu gut
        09241 Pegnitz is die city
        chrissx Media - die Mukke bleibt immer litty
        Andere Rapper find ich nur richtig shitty (shit)
        Sie kommen an und ich fick sie (jaaa)
        Ich mach nen neuen track, der wird krass (krass)
        Ich hoffe für sie, sie feiern den stuff
    - name: Dab
      isrc: GBSMU9522537
      length: 1:00
      lyrics: |
        chrissx macht ein Dab (dab dab dab)
        chrissx macht ein Dab (dab dab dab dab)
        chrissx macht ein Dab (dab dab dab)
        chrissx macht ein Dab (dab dab dab)

        Fick deine ganze Gang (fick sie)
        Fick deine ganze Gang (fick sie)
        Fick deine ganze Gang (fick deine gang)
        Fick deine ganze Gang (fick die gang)

        chrissx is super fresh (fresh)
        chrissx is super fresh (fresh fresh)
        chrissx is super fresh (fresh fresh fresh)
        chrissx is super fresh (fresh fresh fresh)

        Wir machen Money jetz (money)
        Wir machen Money jetz (money money)
        Wir machen Money jetz (money money money)
        Wir machen Money jetz (money money money)
    - name: Piano
      isrc: GBSMU9522538
      length: 57
      lyrics: |
        [Intro]
        (jea)
        (jetz kommen die adlibs on point)

        [Part]
        chrissx spielt Mikado (gaming)
        chrissx spielt Mikado (gaming)
        chrissx spielt Mikado (gaming)
        chrissx spielt Mikado (gaming)
        chrissx spielt Piano (musician)
        chrissx spielt Piano (musician)
        chrissx spielt Piano (musician musician)
        chrissx spielt Piano (music)
        chrissx kriegt ne eins im Musikunterricht (ja)
        chrissx kriegt ne eins im Musikunterricht (streber)
        chrissx kriegt ne eins im Musikunterricht (streber)
        chrissx kriegt ne eins im Musikunterricht (streber)
        chrissx fickt dich und deine Clique (je)
        chrissx fickt dich und deine Clique (fick dich)
        chrissx fickt dich und deine Clique (fick dich)
        chrissx fickt dich und deine Clique (fick dich)

        [Outro]
        (das hat wieder spaß gemacht)
        (lol)
    - name: SIMON Beat Ready
      isrc: GBSMU9522539
      length: 1:50
      lyrics: |
        [Intro]
        (br br br)
        (scur scur scur)
        (hm hm sk sk sk sk)
        (ja ja ja ja ja)

        [Hook]
        Hab den 26SIMON Beat ready, er ist immer noch bereit (ready)
        Hab den 26SIMON Beat ready, er ist super fresh produziert (jea)
        Hab ihn auf Platte und er drippt mega krass (ha)
        Hab den 26SIMON Beat ready und er fickt ihn richtig hart (scur)

        [Part]
        Ich häng wieder in der Schule, was ein großer Abfuck (fock)
        Doch jetz is Pause, bin ne Legende, so wie Sosa, mach Platz (weg)
        Da ist so ein Typ und er fragt mich, was ich in der Mittagspause tu (was)
        Denn er findet mich zuckersüß wiе Mountain Dew (sweet)
        Ich sag, ich schreib freshе Reime, 2016 shit (shit)
        Damit es von mir noch mehr nice Texte gibt (ja)
        Und dann werd ich damit rich (rich)
        Und dann mach ich's wie Iven denn ich kauf ich mir ne whip (S&M)
        Du feierst meine Mukke nich? Man, das finde ich ok (ok)
        Denn ich bin liberal, fick die FDP (fickt euch)
        „Ich esse dich!“ hat für Autotune den weg geebnet (ja)
        Und seit diesem Release kennt mich ganz Pegnitz (scur scur)

        [Hook]
        Ich hab den 26SIMON Beat ready, er ist immer noch bereit
        Hab den 26SIMON Beat ready, er ist super fresh produziert
        Hab ihn auf Platte und er drippt richtig krass (krass)
        Hab den 26SIMON Beat ready und er fickt ihn richtig hart (ja)
        Ich hab den 26SIMON Beat ready, er ist immer noch bereit (scur)
        Hab den 26SIMON Beat ready, er ist super fresh produziert (jea)
        Hab ihn auf Platte und er drippt richtig krass (aha)
        Hab den 26SIMON Beat ready und er fickt ihn richtig hart (jaa)

        [Outro]
        chrissx
        dripshit
        fick dich
        ja ja chrissx
    - name: Young Chri6
      isrc: GBSMU9522540
      length: 1:28
      lyrics: |
        [Hook]
        Young Chri6 auf der 808
        Drei Tage wach und ich fade away
        War nie broke und jetz is Payday, bae
        chrissx Media macht Movies, fick mal Anime

        [Part]
        Es is nachts, ich hab Bock, die ganze Zeit hier zu hängen
        Es is 6 Uhr und ich hab eigentlich kein Bock jetzt zu pennen
        Aber ich leg mich hin, warum denn nicht
        Und schon verschlaf ich den Online-Unterricht
        Ich steh auf um 18 Uhr abends
        Ok, was soll ich jetzt so machen?
        Mukke wär grad nice, aber egal
        Let's play Minecraft mit meinem Vater
        Ich sag: "ich take kеine drugs" und sippe an meinеm Monster
        Ich freestyle so krasse bars man, wie willst du die kontern?
        Du bist krasser Rapper und rappst über deine Schlafenszeiten?
        Junge, ich hab nich mal Schlafenszeiten
        Dieser Text wird zu sick und die Message ist
        Dass bei nem track von chrissx voller Message ist
        Ey yo die Opps werden gecockt
        Dropp ich lines bist du am grillen wie ein Koch

        [Hook]
        Young Chri6 auf der 808
        Drei Tage wach und ich fade away
        War nie broke und jetz is Payday, bae
        chrissx Media macht Movies, fick mal Anime
        Young Chri6 auf der 808
        Drei Tage wach und ich fade away
        War nie broke und jetz is Payday, bae
        chrissx Media macht Movies, fick mal Anime
    - name: Beats - PoC
      isrc: GBSMU9522541
      length: 1:28
      lyrics: |
        [Hook]
        chrissx baut die Beats, chrissx fickt dein ganzes Team (ah)
        chrissx baut die Beats, chrissx fickt dein ganzes Team (ja)
        chrissx baut die Beats, chrissx nimmt dein ganzes Team hops
        chrissx baut die Beats, Deutschrapper whack wie Crackrocks (höö)

        [Part]
        Schule unnötig und whack wie Popschutz (ja)
        Diss mich einmal, ich lauf in deiner Hood mit ner Glock rum (bam bam)
        Schule unnötig und whack wie Webcams
        All eure Skills sind nur second hand shit (shit)
        Ich mach krassen shit in unter ner Stunde (ja)
        Ich mach krassen shit in unter ner Stunde (ah)
        Ich mach krassen shit in unter ner Stunde (ja)
        Für die nächste krasse line brauch ich nicht mal ne Sekunde (ja)
        Andre Rapper labern Scheiße und geben's nicht zu (na na)
        Aber ich rappe schon immer viel zu gut (ja)
        09241 Pegnitz is die city (jea)
        chrissx Media - die Mukke bleibt immer litty (scur scur scur)
        Andere Rapper find ich nur richtig shitty (shit)
        Sie kommen an und ich fick sie (jaaa)
        Ich mach nen neuen track, der wird krass (krass)
        Ich hoffe für sie, sie feiern den stuff (ja)
- name: chwis x ewic
  artists:
    - chrissx
    - depresja
  upc: 5059806959307
  release: 2021-07-23
  cover: https://storage.googleapis.com/routenote-release-cloudfiles/5059806959307/JP5p0vzM90_1624920633.jpg
  link: https://push.fm/fl/cxe2
  tracks:
    - name: untitled1
      isrc: GX3HH2190489
      length: 1:33
      lyrics: |
        [Part]
        Ich bin zurück und die ganze Erde zittert
        Meine Parts bleiben immer noch Punchlinegewitter (Lightning)
        Es ist 4 Uhr und ich schreib' diesen Track
        Es ist 14 Uhr und ich penn' schon wieder weg
        Weil ich war die ganze Nacht wach
        Hacke Code und schreibe Parts, was (hä)
        So läuft mein Leben und du willst das nicht
        Ja genau deswegen mache ich's (fuck you)
        Meine parts nicht immer real, ja man ich lüge auch
        Doch meine alten Feinde halten mir heute die Türen auf
        Das ist Real Rap, denn meistens lüge ich nicht
        Ja ich zocke Minecraft im Online-Unterricht (Gaming)
        Droppe ich mein Album, entflammt ein Feuer
        Simon sieht mein Outfit und sagt „Alter wie teuer“ (Ivy Park)
        Worin sollen diese Lines denn noch münden (was)
        Ich druck AfD-Plakate nur um sie dann anzuzünden
        Ok, deine Scratches sind auf Turntables produziert
        Aber die dopere Mukke gibt es doch ganz klar bei mir
        Bin halbwegs confident, ihr braucht mich nicht beneiden
        Ich wollte in der Schule nebens Klo pissen, aber konnte mich nicht entscheiden
        Imagine du rappst wie all die anderen
        Erzählst mir was für ein krasser Mann du bist
        Das ist whack, weshalb ich ein bisschen anders bin
        Und sag dir zieh Leine dikka - Puppy Kink

        [Hook]
        chrissx macht wieder Mukke
        chrissx macht wieder Mukke
        chrissx macht wieder Mukke
        chrissx macht wieder Mukke
    - name: eulendrip
      isrc: GX3HH2190490
      length: 1:26
      lyrics: |
        [Intro]
        (der wird mich sonst wieder verklagen so)
        (das das neue sieht man das alter)

        [Part]
        chrissx droppt fresh auf den erig (jo)
        Deutschrapper werden geknebelt (pause)
        chrissx ist sehr benebelt (high)
        chrissx übersteuert den Pegel (breit)
        Ich machte es allen klar
        Flo ist lost wie reeeezo
        Deine mutter hört Capital (Bra)
        Meine mutter hört nur Edo (Schreier)
        Ich hoff, dass du hiernach noch ne Frau für's Leben findest
        Weil du nach nem Punch von mir aussiehst wie ne Regenrinne
        chrissx fickt dich wie ne Eule (drip)
        Und das ist ja nix neues (nix)
        Transphobes sagen immer noch dass chrissx der boy ist
        Doch sagt ihr „chris’ mukke is die beste“ seid ihr wahre Ehrenleute
        Just give me weed
        Because i turn on the yeet
        Transphobes would still like walking in my size eleven shoes

        [Outro]
        (das das neue sieht man das alter)
    - name: woher
      isrc: GX3HH2190491
      length: 1:15
      lyrics: |
        Deine Lieblingsrapper sind meine Beute
        Ich hör Deutschrap, du stirbst heute
        Mein Studio kostet mehr als deine letzten fünf Vorschüsse
        Bitch du willst mir was vorrappen, doch ich zieh schon davor Schlüsse
        Mich konnte schon ganz Pegnitz im Geist sehen
        Da war ich noch nicht mal 13
        Ja ich bin in meiner city sowas wie ein Superstar
        Du willst mich battlen, ein Fehler, so wie bei Zunas Mum

        Bald werden meine Vidеos von nem ganzen Filmteam gеdreht
        Mein Vater braucht Google Translate um meine Texte zu verstehen
        Deine Lieblingsrapper werden von mir komplett gerippt
        Ich zieh an ihnen vorbei - im Meriva-Sitz
        Meine Lieblingsrapper canceln deine Lieblingsrapper
        Aber chrissx Media bleibt bis zum end da/Ender
        So wie in Minecraft, das ist real shit
        Feiert mich noch bissl weiter, dann bring ich auch den eric mit

        Ich kill dich, das ist Blut im Coronatest Type Shit
        Und mach dann ein Schritt back so wie Michael
        Mach aus dem Sound dann ein Lied, ja
        Und verlass hypetechnisch dann die Galaxie
        Das ist Franken nicht treu? man fick dich du blöder
        Das ist immer noch realer als Markus Söder
        Ja mir ist egal was du denkst und
        Der kriegt jetz ne Klatsche bro kein Publikum
    - name: funtasy_I
      isrc: GX3HH2190492
      length: 1:08
      lyrics: |
        Ich zerstör Deutschrap in nicht mal einer Sekunde
        Mach wieder ne Memo und die geht schon ne Stunde
        Ich schreib ne deutsche Zeitung und die macht die Runde
        Schreib ne Deutschklausur und hab zwei Punkte

        Hallo guten Tag, ich meinte fick dich du Kleinkind
        Ich hab noch nie Vita Malz getrunken, weiß nicht mal, was dieser Scheiß ist
        In den letzten Jahren verbesserte sich mein Leben sehr
        Beispielsweise hör ich kein Battlerap mehr

        Ich mach was ich mach, egal was du davon hältst
        Doch ich mach es ganz schnell und sag: „Fick UML!“
        Ihr wollt es, doch an meine Skills kommt ihr nicht
        Ich sags euch: Fick das Rapbuis

        Alle fragen: „chris, was ist der Trick?“
        Verrat ich nicht, ihr schiebt Filme, als wärt ihr Netflix
        Eure Lines gehen rechts rein, aber immer wieder
        Kommen sie rechts raus, so wie AfD-Mitglieder
    - name: chwissxbeat
      isrc: GX3HH2190493
      length: 1:22
      lyrics: |
        [Part]
        Deine Rapper sind für mich nur Keks
        Ich finde sie ziemlich whack
        Aber hier kommt die Chefin
        Krasser Style, fickt alles und braucht nicht mal ne Message
        Zeptah hört mich, denkt es ist ein Inferno
        Lyrium sieht n Kommunisten auf nem Euroschein
        Und denkt sich: Wer könnte das denn sein
        Erinnert sich und hört jetz ericenfermo
        Es ist 12 Uhr ich kauf mir Supreme
        Das zu sagen ist unnötig, weil es jeder schon sieht
        Ich hab kein Corona, war vom Schnelltest entzückt ja
        Ich hab kein Corona, seit Monaten alk technisch nüchtern
        Warum Flo wieder gedisst wird im Song
        Warum auch Diertl und Se nen Fick abbekomm
        Ich leak nicht Nudes anderer
        Wie ein ehrenloser Bastard

        [Hook]
        chwissx chwissx
        beat beat beat beat
        chwissxbeat
        chwissxbeat
        chwissxbeat
        chwissxbeat
        chwissxbeat
        Ich fall auf, weil ich krass bin
        Deutschrap wurd gekillt
        Nicht weil ich das war
        Sondern weil ihr das getan habt
    - name: interludegegenewic
      isrc: GX3HH2190494
      length: 1:55
    - name: back to punchline
      isrc: GX3HH2190495
      length: 1:43
      lyrics: |
        [Part 1: chrissx]
        (Punchline)
        Ey chrissx kommt an mit dem zu krassen Flow
        Und nem Racecar das is red, wie die Fahrschule, bro
        Es ist Montag und es ist endlich wieder Schule
        Aber was heißt endlich das ist nicht was ich grad suchte
        Ich hatte vor zwei Monaten Bock endlich wieder Schule zu haben
        Aber Distanzunterricht war gut dadrin des weg zu machen
        Ich hab bis 9 Uhr gepennt und bin um 12 Uhr aufgestanden
        Jetz kann ich den shit nicht mehr machen
        Das is sad but true, was soll ich dagegen tun
        Egal, ich verlier nicht den mut, rapp weiter zu gut
        Jetzt hockt man wieder den ganzen Tag in der Schule
        Das ist auch nicht gerade das was ich suche
        Aber das ist besser, als zuhause zu hocken
        Zu hause ohne Pause zu hocken
        Und vor Arbeitsaufträgen mit der Fertigstellungsdauer von Hausen zu hocken
        Stattdessen ist es wieder normal, zuhause zu hocken
        Nach der Schule oder auch schon während der Pause zu hocken
        Und dafür halt auch in der Schule und nicht nur zuhause zu hocken
        Und den K- zu boxen

        [Part 2: depresja]
        Wieder zuhause hocken, kein Bock
        Politiker sind lost, gehen wir jetz Schule oder nich
        So ein Witz auf einmal heißt es Maske an Maske aus
        Inzidenz low inzidenz high so wie
        chrissx schläft er drei Tage nicht oh shit
        Politiker sind einfach nur Witze
        Sie können sich nicht entscheiden
        Und das auf unsre Kosten und das Zeugnis sagt nur oh shit
    - name: physik
      isrc: GX3HH2190496
      length: 53
      lyrics: |
        [Part]
        Yea ich schreib morgen Physikklausur
        Klingt ja eigentlich ganz gut, ja nur
        Hab ich noch nix gelernt und in der Ex null Punkte
        Ich lese kurz die Themen und nach nicht mal einer Sekunde
        Macht es die Runde: chrissx hat Hass erfunden
        Ja was ein Zyklotron ist, das hab ich nicht verstanden
        Warum sollte jemand so nen Quatsch auch machen
        Und warum sollte jemand sowas in die Schule packen

        [Hook]
        chrissx fickt euch mega
        chrissx schnüffelt Kleber
        chrissx ist ein addict
        chrissx schnüffelt Edding

        [Outro]
        What the fuck ist falsch mit euch
        Ihr seid gottverdammte toys
        Sperr ma an Piazolo in an Kerker
        Macht er sich ganz gut neben Joachim Hermann (Hermann Hermann Hermann)
    - name: fahrstuhl
      isrc: GX3HH2190497
      length: 2:03
      lyrics: |
        [Intro]
        (aber das funktioniert glaub ich)

        [Part]
        Yea es is chrissx, ich hab Homeschooling, richtige Sause
        Aber dafür hab ich jetzt Pause
        Es ist 11 Uhr und ich chill immer noch im Bett
        Dropp nen neuen Part und der wird ein krasses Brett
        Sowas geht nur wenn man zuhause Schule hat
        Weil man dann nämlich vom Bett aus viel macht
        Man steht dann einfach erst in der 6. Stunde auf
        Ja ok Homeschooling is ganz nice so langsam merk ichs auch
        Jea chrissx ich bin im room wo soll ich sonst sein
        Wieder nen Part zu freestylen macht mir mein Kopf rein
        Ich höre t_blade und relaxe bisschen zwischendurch
        Meine Fans haben Hunger auf neue Mukke und ich fütter euch

        [Bridge]
        cwingix cwingix beat beat
        cwingix cwingix beat beat

        [Hook]
        Ich zock wieder den ganzen Tag ACNH
        Und dann mach ich was (wut)
        Ich buy einfach nur clothes den ganzen Tag
        Und das ist dann lit so wie ein burnender Wald (lit)

        [Outro]
        So wie ein burnender Wald
        So wie ein burnender Wald
        Ich burn wieder den ganzen Tag
        Ich burn wieder den ganzen Tag
        So wie ein burnender Wald
        Das is einfach lit, digga lit
        Einfach lit, einfach lit, einfach lit
        So wie ein burnender Wald jaja
    - name: nein
      isrc: GX3HH2190498
      length: 1:40
      lyrics: |
        [Part 1: chrissx]
        Yea es is chrissx, ich hab keine Schule
        Aber ich bin auch die Coole
        Aber ich hab dafür Homeschooling
        Das ist Dreck, so wie Googling
        Ja ich hab gleich Unterricht
        Nämlich Physik
        Und das wird richtig trash
        Deswegen kick ich nen part jetzt
        Ich warte auf die Physikstunde
        Die wird richtig trash, weil ich da in Physik musse
        Oder so ähnlich, dieser reim war nicht gut
        Aber egal, ich mach das mit ruhig blut
        Ja dann später bin ich im Discord
        Und dann bin ich im Discord
        Und dann bin ich im Big Blue Button
        Da tun wir komische Sachen machen

        [Part 2: chrissx]
        So mit Physik
        Und irgendein Mist
        Ich hab auch keinen Plan
        Was das alles ist
        Und Potentialе
        Ich habs halbwegs verstanden
        Abеr keine Ahnung, was soll ich machen
        Yea ich versteh es nicht ganz
        Weil manche Wörter ich einfach nicht kann
        Ich kann nicht mal deutsche Grammatik
        Das merkt man, aber egal
        Das muss man für Deutschrap auch nicht
        (nein)
        (pause)

        [Part 3: chrissx]
        Ich hab grad die Konferenz verlassen
        Insgesamt fand ich die Konferenz belastend
        Ey yo und jetzt pull ich up zu dem breakfast
        Und foode nicen stuff, aber kein egg, man
        Und ich leave die water bottle auf dem desk
        Und bin mega krass am lines spitten, no cap
        Ey yo, das Smartphone ist in meiner hand
        Es ist fast 9 Uhr und chrissx geht ham

        [Part 4: depresja]
        chrissx geht ham wie das Fleisch
        Physik ist dämlicher Scheiß
        Ich bau den Kin Yalle Beat
        chrissx geht ham wie das meat
        Ich bin vegetarian
        Aber auch bester Rapper, man
        Ich geh als Vegetarier ham
        Deine Lieblingsrapper nicht, obwohl die Fleisch essen
        (die gehen Obst oder so)
    - name: kaffeegetrank
      isrc: GX3HH2190499
      length: 1:49
      lyrics: |
        Jea chrissx trinkt einen Kaffee
        Das ist klasse
        chrissx sitzt in der Klasse
        Und alle finden's klasse

        chrissx trinkt nen Kaffee
        Das is super krass, ey
        Und das feiern alle
        chrissx trinkt nen Kaffee

        Ja chrissx trinkt davon viel man
        Doch ist trotzdem nicht wach
        Alle findens krass
        Und denken sich was macht die für stuff

        chrissx geht zu Fielmann
        chrissx braucht eine neue Brille
        chrissx wird es zu viel man
        chrissx geht Deutschrap grillen
    - name: gut so
      isrc: GX3HH2190500
      length: 1:29
      lyrics: |
        [Intro: chrissx]
        (Liebe Genossinnen und Genossen)
        (Für die, die's jetzt auch noch nicht wussten)
        (Ja, ich bin lesbisch, und das ist auch gut so)
        (Damit auch keine Irritationen aufkommen)

        [Part 1: chrissx]
        chrissx Media, egal was du dir reinziehst
        Alles was wir machen wird krasse Musik
        Du machst Rap von Wien bis Bregenz
        Ich mach Rap von Hof bis Pegnitz
        Deutschrap heute Trap, worüber soll ich mich noch ärgern
        90% von euch so dumm wie Joachim Hermann
        chrissx Media steht für die krasseste Musik, die ihr jemals gehört habt
        Merkt euch diese Wörter

        [Part 2: chrissx]
        Ich habe jetzt 1k Tracks in meiner Playlist
        Go tell my mom that I made it
        Meine Snacks kauf ich nur bei Mondelez
        Ich meinte ich klau die bei Rewe
        Wo Sunny der Spanner überall zuguckt…
        Rapper ficken Transen? (Und das ist auch gut so)
        Das sind 900 Kilo clout, ich bin nicht fett
        Ich droppte zwei Alben und jetz bin ich wieder weg

        [Part 3: depresja]
        (Liebe Genossinnen und Genossen)
        (Ich sag's euch auch und wer's noch nicht gewusst hat)
        Wer es noch nicht wusste: ich bin dope
        Flow auch krass und zu gay, aber das ist zweitrangig
        Wen ich ficke, geht dich n Scheiß an
        Ich schreib parts und du glaubtest du weißt alles
        Aber unsere parts bringen wir bei wie es ist
        So krass geownt zu werden mit Rapskill
        Wir sind einfach zu heftig
        ...

        [Outro: depresja]
        Wir sind heiß
        Und tight
        Und pride
        Ja ja ja (äh)
        Ich höre FaulenzA und feier ey ja
        Fühl ich fühl ich fühl ich schon
        Fühl ich fühl ich fühl ich schon, ah
- name: OG in Schorchats
  artists:
    - chrissx
  upc: 5059741789281
  release: 2021-11-19
  cover: https://storage.googleapis.com/routenote-release-cloudfiles/5059741789281/kWuzrlJU5c_1635649945.jpg
  link: https://push.fm/fl/og
  tracks:
    - name: OG in Schorchats
      isrc: GX5MX2143455
      length: 1:08
      lyrics: |
        [Intro]
        (meine Stirn is so voller Schweiß alter)
        (was is denn los)
        (aber egal)

        [Part]
        Hey Leute, wo gibt es die freshesten Beats?
        Na, die werden von mir produziert
        Yea, es ist chrissx und ich schreibe nen Part
        Und der wird krass, das mach ich euch klar
        Ich chill am Oberanger, das ist Schorgast Type Shit
        Straight outta Pegnitz, ’s geht immer weiter, bis
        Man mich in der Charts sieht, ich bleib mir selber treu
        Deswegen klingt meine Mukke auch nie wie diе von euch
        Fick die Musikindustrie und Major Labеls
        Ihr verkauft euch für ne Roli in den Verträgen
        Ich würd vielleicht noch bei einem andern Label signen
        Und ihr wisst ja auch, bei was für eim
        Aber chrissx Media ballt immer weiter
        Und bald ham wir die Edge/EDGE so wie Mobilfunkbetreiber
        Wenn ich mich in die richtige Corner hock
        Hab ich sogar wirklich EDGE, oh my god
- name: Ich erwähle euch!
  artists:
    - chrissx
  upc: 5063010740828
  release: 2021-12-13
  cover: https://storage.googleapis.com/routenote-release-cloudfiles/5063010740828/SRfcXiq7q7_1646054000.jpg
  link: https://push.fm/fl/ied2021
  tracks:
    - name: Ich erwähle euch!
      isrc: GX3HH2238812
      length: 44
      lyrics: |
        [Part]
        Dieses Jahr ist die Prestige nicht den KeKz zu dissen
        Sondern Hornis und Brezen ficken
        Ihr müsst halt eben wissen, eigentlich muss es jeder wissen
        Dass die Bastards eh nur snitchen und auf Regeln pissen
        Wir werden 3000 rechnen
        Bitch ich schreibe diesen Disstrack schon seit 2016
        Und du dachtest - 2017, WhatsApp: es kommt kein Disstrack
        Da täuscht du dich, denn ich diss Keks
        Das Hornliu ist eine Maskerade
        Nein, das Hornliu ist eine Rattenplage
        Doch ich beende es – Kampfansage

        [Outro]
        (na, das war nich gut, glaub ich)
- name: Rentner im Racecar
  artists:
    - chrissx
  upc: 5063011715337
  release: 2022-03-25
  cover: https://storage.googleapis.com/routenote-release-cloudfiles/5063011715337/JSo6GOj1bR_1647127489.jpg
  link: https://push.fm/fl/rentner
  tracks:
    - name: Rentner im Racecar
      isrc: GX3HH2267350
      length: 1:14
      lyrics: |
        [Intro]
        (so, grad schon drei tracks recordet)
        (jetz wird der vierte recordet)
        (ah ne)
        (ich hab erst die hälfte des tracks überhaupt geschrieben)
        (ich hab den track noch gar nich fertig geschrieben hahahaha)
        (ich-ich bin einfach so lost)
        (ich weiß noch nich mal was ich fertig geschrieben habe)
        (und was nich haha)

        [Part 1]
        Ich chill mit dem Rentner im Racecar
        Wir ballern uns beyda
        Und ich sag zu ihm ey schau mal, was ich seh da
        Sind wir up und schon kommen die hater
        Die meinen das nich real das war eh klar
        Is ja eigentlich n gutes Thema
        Alles was ich sage hat keine Fehler 
        Ich komme auf die Bühne und schrei: „Hier kommt der Jäger!“

        [Part 2]
        Du bist jetzt auch ein rapp…na, bro wieso?
        Für die bin ich doch viel zu dope
        Mir fehlen in deren szene die role models
        Weil die nur whack rappen und ihre Fans abzocken
        Und wehe ein Rapper wagt es nochmal während ich red zu sprechen
        Ich bin bewaffnet - drei USB-Sticks in meinem Federmäppchen
        Google Maps sagt: chrissx Media ist ein top team
        Nur ich hock in Schorchats und hab kein service so wie launchd
- name: Mukke aus der Versenkung Ⅱ
  artists:
    - chrissx
  upc: 5059962958862
  release: 2022-06-03
  cover: https://storage.googleapis.com/routenote-release-cloudfiles/5059962958862/yPOfV9iaqw_1652992508.jpg
  link: https://push.fm/fl/madv2
  tracks:
    - name: kein dadash
      isrc: GX5MX2226074
      length: 20
      lyrics: |
        [Intro]
        (ich schrei rum und das ist auch gut so)

        [Part]
        „Mukke aus der Versenkung“ is’ echt schon lange her
        Doch die Fans sind greedy, sie wollen immer mehr
        Obwohl das nur das ist, was nix und das schon lange war
        Trotzdem immer noch besser als jeder andere Bastard

        [Outro]
        (bam)
    - name: moodkl
      isrc: GX5MX2226075
      length: 48
      lyrics: |
        [Intro]
        (OK)
        (Ich hör)
        (Ich hör die boa)
        (Oh shit)
        (Ich muss die Lyrics noch hintun)
        (Hertun)

        [Part]
        „Mukke aus der Versenkung“, lange her
        Doch die Fans sind greedy, wollen immer mehr
        Obwohl das nur das ist, was nix und das schon lang war
        Trotzdem besser als jeder andere Bastard

        [Outro]
        (war ok)
        (ah ne, kann ja drin bleiben)
        (pause, big pause, aber was heißt pause, das ja eigentlich nice)
    - name: Livestream
      isrc: GX5MX2226088
      length: 45
      lyrics: |
        [Intro]
        Yea es is chrissx

        [Part]
        „Mukke aus der Versenkung“ is’ echt schon lange her
        Doch die Fans sind greedy, sie wollen immer mehr
        Obwohl das nur das ist, was nix und das schon lange war
        Trotzdem immer noch besser als jeder andere Bastard

        [Outro]
        (bam, junge)
    - name: Schorchats Ⅱ
      isrc: GX5MX2226089
      length: 46
      lyrics: |
        [Intro]
        Bam
        Bam
        Bam
        (wie ging des nochmal, weiß ich gar nich mehr)

        [Part]
        „Mukke aus der Versenkung“ is’ echt schon lange her
        Doch die Fans sind greedy, sie wollen immer mehr
        Obwohl das nur das ist, was nix und das schon lang war
        Trotzdem immer noch besser als jeder andere Bastard
    - name: WTF
      isrc: GX5MX2226090
      length: 30
      lyrics: |
        [Intro]
        (boa ich kann mich so gar nich mehr an vorgestern erinnern)
        (ich weiß gar nich mehr was passiert is)

        [Part]
        Mu-„Mukke aus der Versenkung“, lange her
        Doch die Fans sind greedy, wollen immer mehr
        Obwohl das nur das ist, was nix und das schon lang war
        Trotzdem besser als jeder andere Bastard
    - name: WeirdGlitch();
      isrc: GX5MX2226114
      length: 38
    - name: блять кол
      isrc: GX5MX2226115
      length: 24
      lyrics: |
        „Mukke aus der Versenkung“ is’ echt schon lange her
        Doch die Fans sind greedy, sie wollen immer mehr
        Obwohl das nur das ist, was nix und das schon lange war
        Trotzdem immer noch besser als jeder andere Bastard
- name: Abitur
  artists:
    - chrissx
  upc: 5063112770235
  release: 2022-06-24
  cover: https://storage.googleapis.com/routenote-release-cloudfiles/5063112770235/EMH6ZXplFT_1654263752.jpg
  link: https://push.fm/fl/abi
  tracks:
    - name: deitsch
      isrc: GX5MX2256291
      length: 1:21
      lyrics: |
        [Intro]
        (Abi geladen, starte Deutsch)

        [Part]
        Nein, bitte nicht (nein)
        Ich hab doch noch gar nix gelernt (scheiße)
        Bitte, lassen Sie mich (weg)
        Meine Ehre wird sonst entfernt (shit)
        Aber egal, es geht los mit Deutsch
        Ich schreib ne deutsche Zeitung und das nicht erst seit heut (oh)
        Aber wenn ich an chris x eric 2 denke, könnt ichs auch lassen
        Weil da hab ich gesagt, dass ich trotzdem nur zwei Punkte hatte (ja)
        Mein Schlafrhythmus ist abgefuckt (fuck)
        Also viel Spaß bei nem Kommentar (ja)
        Und statt zu lernen schreib ich bars (skr)
        Und bleib wach die ganze Nacht (realtalk)
        Wie soll ich auf Deutsch lernen, sag wie (wie)
        Es ist 4 Uhr nachts und ich mach Musik (ye)
        Hoffentlich flex ich bald nicht nur mit Supreme (nah)
        Sondern auch mit Noten, mein G (yea)
    - name: englishc
      isrc: GX5MX2256294
      length: 1:12
      lyrics: |
        [Intro]
        (Starte Englisch)
        (round two fight)

        [Part]
        Yea es is chrissx ich fick deine Crew (fuck you)
        Und hab zwei Liter Kondenzwasser an meinem mtn dew (wet)
        Jetz is mein T-Shirt nass (wet)
        Jetz sieht keine mehr dass ich drip hab (shit)
        Du flext damit, dass du auf Gras stehst wie ein Gärtner
        Ich flex damit, dass ich immer noch nich gelernt hab
        Es ist 6 Uhr nachts und Englisch steht an (ja)
        Aber egal, Deutsch hat doch ganz gut geklappt (ja)
        Ich war nen Tag wach (oh)
        Und hab noch nen Kommentar (ja)
        Über AI in der Literatur geschrieben (oh)
        Das ganze Kollegium wird ihn lieben (yessur)
        Wie soll ich auf englisch lernen, sag wie (wie)
        Es is 6 Uhr morgens und ich mach Musik
        Hoffentlich flex ich bald nicht nur mit Supreme
        Sondern auch mit Noten mein G (je)
    - name: mthematik
      isrc: GX5MX2256305
      length: 53
      lyrics: |
        [Intro]
        (Starte Mathe)

        [Part]
        Nein, bitte nicht (ah)
        Digga, das wird so trash (fuck)
        Gelernt hab ich bis jetz noch nix (shit)
        Aber drei Stunden hab ich noch yea (ja)
        Wobei ich muss ja noch nen track machen (yea)
        Also muss zum Lernen circa eine Stunde langen (oh)
        Das wird sehr witzig dann später (yex)
        Machen wir die Turnhalle zur Arena (ye)
        Und ich werd queen weil ich mathe kann (ye)
        Jk ich hoff nur dass ich nich so hart suck (crap)
        Deutsch und Englisch war doch auch ganz ok (ye)
        Dann wird Mathe schon auch gehen (hoffentlich)
        Wir verfahren einfach wie in der Regel (ye)
        Ich kann den halben Stoff nich, egal ich lass das zu (ja)
        Ich hab nix gelernt, aber wird schon gehen (jo)
        So langsam klingts als ginge Mathe doch ganz gut (ok)
    - name: einewoichefrei
      isrc: GX5MX2257310
      length: 2:37
    - name: infromatik
      isrc: GX5MX2257311
      length: 1:02
      lyrics: |
        [Intro]
        (Starte Info)

        [Part]
        Ich zerlege lauter noobs, nicht nur im Studio (wo noch)
        Weil ich bin Informatik-Pro (oh)
        Naja, geht schon ich hab kaum gelernt (shit)
        Auch weil die Themen schon gehen wer’n (ye)
        Ich hab noch zwei Stunden und mach nen track (track)
        Danach geht’s wieder ins Internet (Pornos)
        Mebis und ich kann noch paar Minuten lernen (lernen)
        Um die Wahrscheinlichkeit zu verkacken zu entfernen (ye)
        Ich hatte zwei Wochen Zeit seit Mathe (oh)
        Da kann man Studioumbauten machen (ja)
        Quake Champions zocken (nice)
        Und scheiße dann Informatik rocken (fuck)
        Ich hab kaum gelernt (schon ok)
        Meine Ehre wird entfernt (oh ne)
        Egal das wird schon geh’n (mehr bars)
        Info aber vorher wird das Studio zerlegt (viel spaß)
    - name: witshcaft
      isrc: GX5MX2257321
      length: 49
      lyrics: |
        [Intro]
        (Starte Wirtschaft)

        [Part]
        Oh fuck no (nein)
        Ich hab halt keine Ahnung und so (hä)
        Lernen, ich sag dir bro (was)
        Jetz wird durchgezogen kein Koks (oh ja)
        Es ist 9 uhr und ich mach nen track (track)
        Danach gehts wieder ins Internet (Pornos)
        Dann wird wieder gelernt (lernt)
        Ich hoff das wird gut im Ernst (ja)
        Ich hab kein bock wegen Wirtschaft mein Abi zu verkacken (oh no)
        Deswegen wirds Zeit jetz action zu machen (oho)
        Keine Zeit, ich muss weiter lernen (aha)
        Um die Wahrscheinlichkeit zu verkacken zu entfernen (ja)
        Muss den track beenden, fuck, ich muss lernen (shit)
        Ich muss schnell weg, fuck, ich muss lernen (shit)
        Keine Zeit mehr, fuck, ich muss lernen (shit)
        Gleich WR, fuck, ich muss lernen (fuck)
    - name: paryt
      isrc: GX5MX2257322
      length: 1:04
      lyrics: |
        [Intro]
        (400 Keine weiteren Abifächer)

        [Part]
        Yea es ist chrissx ich hab letztens gesagt
        Dass ich jubel, wenn ich im Abi ne 2, vorne dran hab
        Jetz hab ich 2,9
        Und ich würd nich grad sagen, dass ich mich nich freu
        Wobei, das so genau zu sagen ist schon ziemlich schwer
        Meine erste Reaktion weiß ich nämlich nich mehr
        Weil dafür war ich an dem Abend bissle zu drunk und so
        Egal, was mach ich jetz, keine Ahnung bro

        [Bridge]
        Dö Dö Dö
        Dö Dö Dö
        Dö Dö Dö
        Dö Dö Dö

        [Outro]
        Nie mehr Schule
        Keine Schule mehr
- name: Kurva - Acoustic Diss
  artists:
    - chrissx
  upc: 5063113511141
  release: 2022-08-29
  cover: https://storage.googleapis.com/routenote-release-cloudfiles/5063113511141/gambxd4B5N_1661731410.jpg
  link: https://push.fm/fl/kurva-ad
  tracks:
    - name: Kurva - Acoustic Diss
      isrc: GX89G2211691
      length: 1:13
      lyrics: |
        [Part 1]
        Jsi kunda
        Jsi kunda
        Jsi kunda
        Jsi kunda
        Jsi kunda
        Jsi kunda
        Jsi kunda

        [Hook]
        Frederik
        Kurva
        Frederik
        Kurva
        Frederik
        Kurva
        Frederik
        Kurva

        [Part 2]
        Jsi kunda
        Jsi kunda
        Jsi kunda
        Jsi kunda
        Jsi kunda
        Jsi kunda
        Jsi kunda
        Jsi kunda

        [Hook]
        Frederik
        Kurva
        Frederik
        Kurva
        Frederik
        Kurva
        Frederik
        Kurva
- name: Planlos 4 – Testo vorbei EP
  artists:
    - chrissx
  upc: 5063341565893
  release: 2023-08-11
  cover: https://storage.googleapis.com/routenote-release-cloudfiles/5063341565893/ELMHfrfOav_1690499699.jpg
  link: https://push.fm/fl/p4
  tracks:
    - name: Essential
      isrc: GX3HH2365905
      length: 2:39
      lyrics: |
        [Part 1]
        chrissx droppt auf 'n freshen beat hinein
        Hör nur kurz rein und du checkst den vibe
        Ich hatte nie Zeit trotzdem schrieb ich lines
        Jetz' bin ich hyped weil mein neues tape wird geil (nice)
        Es ist Planlos 4 und ich bleibe real
        Dieses Rapgame ein leichtes Spiel (einfach mies)
        Kein Respekt für die Rapper die nur rappen wegen Geld
        Die sich prostituieren für Chartplatzierungen irgendwo auf der Welt
        Aber shoutoute deine Fans in Asien
        Du bist Platz 1 in Saudi Arabien (the fuck)
        What the fuck was geht bei dir ab
        Gestern hast du noch Mukke für Deprikids gemacht
        Deine Gang alles echte Dealer ihr macht heftig lila
        Ich hör Kim Petras auf Handyspeaker (bitch)
        Aber egal ich spitt' gut
        Ich bin der Drypfux du bist ein Nichtsnutz

        [Hook]
        Ich bin für das Game sowas wie essentiell
        Deine Gang kriegt häfftig Schellen
        Ihr seid alle lachhaft - Capucchino kriminell
        chrissx Media, ich glaub wir verändern die Welt

        [Bridge]
        Ich bin für das Game sowas wie essentiell
        Deine Gang kriegt häfftig Schellen
        Capucchino kriminell
        Verändern die Welt

        [Part 2]
        Früher im Tesla, heute im Daimler
        Damals trugst du noch kein Designer
        Damals nur talk über drugs in den lines ja
        Heute nur flex mit du bist reich fuck
        Wo bist du nur falsch abgebogen
        Früher nicer lifestyle und du willst nach oben
        Heute flex mit Rolis ja du bist abgehoben
        Und jeder track ein Placement für Keine Macht Den Drogen
        Du willst rappen? Is oke nur bringt nix
        Ich hock mit der Gang in 'nem BMW, wir hören chrissx (beat)
        Und die Jungs feiern wenn wir's uns geben
        Und die Apple Watch matcht mit meinen Nägeln (red)
        Keine macht das was ich mach
        Ich rapp' und deine Lieblingsrapper sind abgefuckt
        Du bist mies am Arsch weil ich rappe zu krass
        Ich hab's erst geschafft wenn Der Biograph über mich 'n Video macht

        [Hook]
        Ich bin für das Game sowas wie essentiell
        Deine Gang kriegt häfftig Schellen
        Ihr seid alle lachhaft - Capucchino kriminell
        chrissx Media, ich glaub wir verändern die Welt

        [Bridge]
        Ich bin für das Game sowas wie essentiell
        Deine Gang kriegt häfftig Schellen
        Capucchino kriminell
        Ändern die Welt
    - name: So high
      isrc: GX3HH2338782
      length: 2:32
      lyrics: |
        [Hook]
        Ja wir sind so high
        Tausend Wolken fliegen an uns vorbei
        Nimm dir die Zeit
        Und spür diesen vibe

        [Bridge]
        Ja wir sind so high
        Ja wir sind so high
        Tausend Wolken fliegen an uns vorbei
        An uns vorbei

        [Part 1]
        In meinem Monster sind Eiswürfel
        Aus dem Gefrierfach obwohl's nie da rein dürfte
        Ich nehm' 'nen sipp und fang an zu rappen
        Hopp' dann ins car und schon startet die action
        Fünf Stunden Fahrt und ich bin komplett faded
        Noch bissle später: Jea, we fuckin' made it
        Gurgel mit der Gang 'nen Coronatest
        Danach 'paar Stunden warten das' oberwhack
        Pennen im car, selten so lost gewesen
        Um am Nachmittag dann ins Hotel reinzugehen
        Weiterpennen in meinen baggies
        Es is Abend, Jan Böhmermann im Handy
        Guten Morgen, auf in die Stadt
        Ins Haus, wo man den Paul treffen kann
        Nach zwei Stunden dann wieder nach Hause
        Bin komplett faded obwohl ich nichts rauche

        [Bridge]
        Ja wir sind so high
        Ja wir sind so high
        Tausend Wolken fliegen an uns vorbei
        An uns vorbei

        [Hook]
        Ja wir sind so high
        Tausend Wolken fliegen an uns vorbei
        Nimm dir die Zeit
        Und spür diesen vibe
    - name: K.B.B.
      isrc: GX3HH2375460
      length: 3:23
      lyrics: |
        [Intro]
        Keine Basic Bitch

        [Part 1]
        Jea ich spitte die lines, und ich drippe zu nice und ich ficke dei'n Scheiß
        Keine andere hat mein Swagger-Style, mein Swagger-Life, mein Channel nice
        Ich hacke dich mit Wasmer und make deine pronouns was/were
        Sippe immer noch am Monster, deine ganze Gang will auf mein concert
        Audacity am loaden, GSnap auf den Vocals, bitch ich hab noch immer diesen vibe als ob ich morgen broke bin
        Dein Niveau noch niedriger als MiiMiis Twitter, und ich fick deinen Körper so wie ein Gewitter
        Hälfte smartest rapper, Hälfte Bimbo, und könnte immer noch cryen vom Bisacast Intro
        Du weißt nich' mal was mit „Nazi Phase“ gemeint ist, also geb ich ein' Fick wer du bist
        Ich hab in der Sechsten Alben geschrieben, du Latein Schulaufgaben
        Und hab auch langsam Bio gelernt, weil ich musst' es machen
        Dein Schicksal vorbestimmt, meins ist in meiner Hand
        Keiner deiner Jungs hat so viel balls wie 'ne tranny vom land

        [Bridge/Hook]
        Jea ich spitte die lines
        Und ich weiß
        Es
        Bad bitches
        Ex Christians
        Keine Basic Bitch

        [Part 2]
        Yea ich fick dich weiter, trillest tranny in der cypher
        Was ich mach hat viel mit trap zu tun, obwohl ich diesen Monte nich' feier
        Von Ghettobra und Socken zu realem bra und sponges, das is als würde Vocal 'n gutes Album droppen
        Auf meinem Twitter nur Banger-Tweets, ich bin nich behindert, ich messe nicht in feet
        Ich hab euch den Moneyrain-Sound gezeigt, ihr habts nich gepeilt, schade halt
        Ja ich hab trap gemacht und sie ham gelaught, jetz fickt mein sound ihrn ganzen arsch
        Du bist ein G, du Kind? Ich hab ein g string, fick dich
        Erzählt mir nix, ich geh mit euch lappen putzen, denn ihr seid nur nullen hinter der eins ich bin abgerundet
        Du bist rapper, weil dein outfit kostet Mios
        Mein Rock zwölf Euro, mein Studio paar racks, ich setze Prios
        Ich schau Great Scott du suckst AIDS cocks
        Keine Hoffnung für Deutschrap wenn mein nächstes tape kommt

        [Bridge/Hook]
        Jea ich spitte die lines
        Und ich weiß
        Es
        Bad bitches
        Ex Christians
        Keine Basic Bitch

        [Part 3]
        Der shit den ich spitt' macht dich kaputt kein Alkohol
        Ich lauf rein nach Pegnitz und die Paras kommen mich wieder holn
        Ich zeig dir Dinge, die hast du nie gesehen, ich zeig dir die Dinge, die hast du nie erlebt
        Von Selfbondage mit elf und YouTubeGeld zu eigene Firmen ging sehr sehr schnell
        Von jeden tag suicidal thoughts zu frühestens nach dem TKUM drop
        Von dass ich nachts in der Küche bin und die Paras holen mich
        Zu nur wenn es nachts regnet oder ich Menschen sehe
        Von keine Hoffnung für mein Leben zu „ich hab Bock“ wär ein understatement
        Deutsche Rapper streamen jetzt auf Twitch, das mach ich seit Jahren nicht
        Mehr. Ich hacke Twitch und setze die Offsets – Ric Flair
        Ich baller die lines und zeig dir dass dein Rap Scheiß ist
        Und war mit 14 tiefer in der BDSM Community als du je sein wirst

        [bridge/hook]
        Jea ich spitte die lines
        Und ich weiß
        Es
        Bad bitches
        Ex Christians
        Keine Basic

        [Outro]
        Keine Basic Bitch
    - name: Keiner kommt klar mit mir
      isrc: GX3HH2352885
      length: 1:46
      lyrics: |
        Keiner kommt klar mit mir, und alte Freunde hassen mich
        Ja ich fick dein Vater hier, und gib dir danach ein Stich
        Ich zock Minecraft mit trans twitter, die lügt doch
        Denn ganz trans twitter hat mich schon seit Jahren geblockt
        Pencil Skirt so kurz du kannst meine balls lecken
        Du heulst in deinem Safespace doch du wirst nie so rappen
        Noch nie jemanden geblockt mit dem ich vor paar tagen noch cool war
        Und stolz drauf dass ich noch nie eine Freundschaft beendet hab
        Keiner kommt klar mit mir, und alte Freunde hassen mich
        Noch nie Spongebob gesehen aber kann jede SpongeBOZZ Runde auswendig
        Ich rocke das Basketball Jersey und den Tennis Skirt
        Sag mir bitte noch einmal dass ich unsportlich bin
        Du flext damit, 'nen Benz zu fahren und high zu sein
        Aber ich werf dir mit 'ner Fluke die Scheibe ein
        Du willst leben wie wir, das ist nicht so leicht
        Weil du makest nich' so Dreier wie Rhyne Howard, nein
        Keiner kommt klar mit mir, und alte Freunde hassen mich
        Ich ficke was du repräsentierst, und die ganze Welt veränder ich
        Die Mukke fühlbar, der vibe spürbar
        Hol meinen Choker aus'm Tiefkühler, Viktor und Kevin bleiben immer Brüder
        Ihr seid für'n Arsch wie Klobrillen, ich könnt euch einfach so killen
        Wir sind high-end wie Maltes Drohnenfilme, ohne Witze
        Ihr seid alles wannabes, ich chill mit Transen und Gs
        In einer Hotelsuite, ein Tag in meinem Leben du machst Suizid
        Keiner kommt klar mit mir, und alte Freunde hassen mich
        Weil ich nie standard wird und das erwarte ich
    - name: Testo vorbei
      isrc: GX3HH2357336
      length: 2:05
      lyrics: |
        [Hook]
        Alle sagen, was ich mach is richtig nice
        Ich kaufe Estra unter Listenpreis
        Denn August is Testo vorbei
        Denn August is Testo vorbei, singt es mit mir Leute
        Und jetz' schaut nochmal alle wie gefickt ihr seid
        Ich kaufe Estra unter Listenpreis
        Denn August is Testo vorbei
        Denn August is Testo vorbei, singt es mit mir Leute

        [Part 1]
        Bitches haben Prog, ziehen lines
        Und WhackMCs meinen, sie sind gehypet
        Keine 15 mehr, aber ein Blåhaj
        Und keine Boymoder, doch August ist Testo vorbei

        [Bridge]
        (Imagine du hast so ein Gel und da is E drin)
        (Du hast ein Gel und da is E drin)

        [Part 2]
        Ich baller' Estra transdermal (das leicht)
        Und du ballerst nur einen Wal (kein Haj)
        Ich reiße AfD-Plakate nicht mehr ab
        Sondern zünd' sie gleich an
        Und ich popp' 'ne CPA (yay)
        Und du bist ziemlich gay (based)
        Ich turn up – Bleybley
        Und sippe Monster, nein ich rauche keine Jays
        Der Bulle merkt: Das in meinen Panties ist kein Weed
        Und er geht auf die Knees, sweet
        Ja und ich rap wie noch nie, deutsche Kim P
        Und alles ist real, was du im Video siehst
        Du willst Rapbeef und wirst von Transen erstochen
        Du feierst Proletopia, ich hab doch gesagt du bist 'ne Fotze
        Dieser Fler sagt ich seh aus (wie ein transgender Model)
        Und ja ich ficke dich du (Bottom)

        [Hook]
        Alle sagen, was ich mach is richtig nice
        Ich kaufe Estra unter Listenpreis
        Denn August is Testo vorbei
        Denn August is Testo vorbei, singt es mit mir Leute
        Und jetz' schaut nochmal alle wie gefickt ihr seid
        Ich kaufe Estra unter Listenpreis
        Denn August is Testo vorbei
        Denn August is Testo vorbei, singt es mit mir Leute
- name: Planlos 5
  artists:
    - chrissx
  upc: 5063416799505
  release: 2023-12-15
  cover: https://storage.googleapis.com/routenote-release-cloudfiles/5063416799505/SUV7rnVVsx_1700687013.jpg
  link: https://push.fm/fl/p5
  tracks:
    - name: Hiroshima
      isrc: GX5MY2316087
      length: 1:15
      lyrics: |
        Es is Planlos 5
        Es is Planlos 5
        Es is Planlos 5
        Es is Planlos 5
        Ich hab keinen Plan was ich mache
        Aber ich schlaf auch nich
        Es is Planlos 5
        Es is Planlos 5
        Ich hab keinen Plan was ich mache
        Es is Planlos 5
        Es is Planlos 5
        Aber ich schlaf auch nich
    - name: Unsere Skills
      artists:
        - chrissx
        - Naeyiwu
        - Mister Häfftling
      isrc: GX5MY2319910
      length: 1:47
      lyrics: |
        [Part 1: Naeyiwu]
        Ich trinke Bier und rauche Crack
        Jeden Tag, denn ich habe Swag
        Bling bling macht der Kronkorken
        Ich werde deinen dummen Sohn morden, wooh
        Nazi-Hurensöhne meinen AfD is cool
        Cool, ich meine AfD sind Nazi-Hurensöhne
        Ich nehme dich nich ernst wie im Kindergarten Sören
        Dieser Penner, ey, wooh
        Dein scheiß Tape mach ich in einer fucking Woche
        Drei Tage recorden, die restlichen vier bin ich mies besoffen
        Meine erste EP kam, da war ich im Kindergarten
        Mois, die war so lit, ich tat damit die Kinder braten
        Und boy, ich habe deine Mum gefickt
        Keine Message hinter dieser Line, habe deine Mum gefickt
        Und sie würde so gerne mal wieder bummsen
        Brandopfer-Bitches wissen: Ich bin homogay (burr)

        [Hook: Mister Häfftling]
        Du willst mit deiner Mukke krass was reißen
        Wir kommen vom Land hier, stinkts nach Scheiße
        Vor unseren Skills musst du dich verneigen
        Und im Battle besser keine Runde einreichen

        [Part 2: chrissx]
        Meine Lyrics lesen sich wie ein RAF-Bekennerschreiben
        Deshalb wollen Bullen meine Fresse zeichnen
        Es ist chrissx, ich bin das was du nie gesehen hast
        In meiner Gang nur Rapper und Klimakleber
        Immer noch kein Richgirl
        Deine Rapper blown wie Lüfter
        Du bist ein Addy, ich hör Katy Parry
        Ich popp CPAs und du poppst noch Xannys
        Du takest drugs, ich häng den ganzen Tag im Stu
        Es popp ich auch, aber ich meins nich wie du
        Ich rapp auf beats nur, wenn die häfftig sind
        Du bist mir unsympatisch, du genderst nicht

        [Outro: Mister Häfftling]
        Du willst mit deiner Mukke krass was reißen
        Scheiße
        Dich verneigen
        Keine Runde einreichen
    - name: Blatt und Grün
      isrc: GX5MY2335455
      length: 2:07
      lyrics: |
        [Part 1]
        Yea ich ficke deine Gang immer weiter
        chrissx on top, keine Karriereleiter
        Und alle deine Homes bleiben Baiter
        Keine kann mich übertreffen im Rappen
        Aber OMG, die Transe hat die Femboy-Connection
        Ich shoot auf sie, nicht wie auf Nazis
        Sie is paar Jahre älter, trotzdem bin ich ihre Mommy
        Ich steh über canceln, auch wenns mich manchmal einholt
        Bin ne Top, ich popp jetz Viagra zu meinem Cypro
        Wir sind grundverschieden, nicht wie Blatt und Grün
        Und die eine Bottom schnarcht voll süß
        Ja die Transe meinte: Komm wir machen ein Feature
        Ich hab den part recordet, erig meinte mach sowas nie wieder
        Komm nimm den designer shit, davon hab ich viel zu vieles
        Probier ihn an, doch meinen Swagger kannst du nich kopieren
        Warum disst du mich? Ich blas für Tauis, du für TikTok-Klicks
        Junge deine Wichsvorlage wichst auf mich
        Dein letztes Album war ein Griff ins Klo
        Und du siehst wie Ski Aggu aus mit deiner Vision Pro
        Sie hat n Problem aber klärt das nich friedlich
        Weil sie weiß dass sie nach paar Worten wieder auf den knees is

        [Part 2]
        Trannies sind die einzig wahren Hustler im Game
        Und ich kann die „bitte sag du lebst“ DMs nicht mehr zählen
        Heute weit entfernt von „ich will nicht leben“
        Heute lebende Legende so wie Till Wege
        Und Junge, du kannst uns nich vergleichen
        Nehmen beide Drogen aber wir meinen nicht die gleichen
        Von Coburg bis Nürnberg ich kann es beweisen
        Meine Homies poppen Östrogen mit schwedischen Haien 

        [Bridge]
        Und von Otokonoko bis EstraLabs
        Hoffe ich sie verstehen es jetzt
        Hass mich gerne und cancel mich weg
        Aber ich bleib die erste sicke Transe im Rap

        [Outro]
        Yea ich ficke deine Gang immer weiter
        chrissx on top, keine Karriereleiter
        Keine kann mich übertreffen im Rappen
        Aber OMG, die Transe hat die Femboy-Connection
        Wir sind grundverschieden, nicht wie Blatt und Grün
        Und die eine Bottom schnarcht voll süß
        Ja die Transe meinte: Komm wir machen ein Feature
        Ich hab den part recordet, erig meinte mach sowas nie wieder
    - name: Schwedische Haie
      isrc: GX5MY2323088
      length: 59
      lyrics: |
        Yea es is chrissx, Dorftransen-Attitüde
        Ich soll sterben, wenn ich auf diesem track nur einmal lüge
        Aber weiterhin kein cash auf meinen Konten
        Und eigentlich is die Switch bei mir Bottom
        Ich rap weiter von Coburg bis Nürnberg
        Drei nackte girls in nem Discord das' nix für bürger
        Ich bin Self-Maid, mein KinkCheck based
        Und bevor ich mir Spiro geb, geb ich mir H
        Schwedische Haie - ham wir immer dabei
        Schwedische Haie - ham wir immer dabei
        Schwedische Haie - ham wir immer dabei
        Schwedische Haie - sie machen uns frei
        Schwedische Haie - ham wir immer dabei
        Schwedische Haie - sie machen uns frei
        Keine Anxiety-Attacken mehr auf der Straße
        Schwedische Haie kuscheln und in Ruhe schlafen
    - name: Inspired by SIMON
      isrc: GX5MY2375211
      length: 59
      lyrics: |
        Yea es is chrissx, ich fick deine Crew zehn mal (fickt euch)
        Das mein ich ernst, weil ihr seid AfD-Wähler (Nazi)
        Ich bin oldschool, du willst Stress, ich fick dich in nem Hasenstall (bam bam)
        Ich bin oldschool, ich mach mein Stu kaputt nur mit nem Basketball (bam bam)
        chrissx Media - Ungeheuer (oh)
        Wir können euch absetzen - keine Steuer (katsching)
        Dein Labelchef brüllt wie ein Leutnant (wow)
        Er schweigt bald, wir machen Feuer (lit)
        Rapper werden nich gefickt, sie werden gerapet (oh)
        chrissx macht Mukke und die is great (yessur)
        Ein neues Release, ein monumentales tape (yesur)
        Und du redest vom Mondlandungsfake (pff...hahaha)
        Rapper gehn für Features auf die Knie das ein Deepthroat (hot)
        Du willst auch Mukke machen aber du bist nicht Rezo (no)
        Deine Gang pullt up, schon is sie tot (rip)
        Denn meine Mukke is nich inspired by simon (nope)
    - name: Richgirl
      isrc: GX5MY2383176
      length: 1:27
      lyrics: |
        [Intro]
        Kein Richgirl
        Kein Richgirl
        Kein Richgirl
        Kein Richgirl

        [Part]
        Ich bin die Eins keine Zia Cooke
        Und wenn ich dich kill ist das kein Verlust
        Boys würden mich gerne ficken als Seitensprung
        Und auch girls finden mich einfach gut
        Take keine drugs, vielleicht werd ich mich nie ändern
        Aber ich glaub ich popp bald
        Du sippst kein Monster, du bist ein trender
        chrissx ist Legende wie der Echte Gangster
        Ich hab Homeschooling geschwänzt um tracks zu machen
        Und gerappt während andere am Handy waren
        Deine history ist eine des Versagens
        Und ich glaub ich veränder alles
        Du sagst ich seh aus wie Mickey Mouse mit meinen Kopfhörern
        Aber deine Lautsprecher haben keine Hochtöner
        Ich sag immer noch fick dich du Blöder
        Und trinke Hafermilch so wie Gerhard Schröder

        [Outro]
        Es ist immer noch chrissx
        Immer noch chrissx
        Es ist immer noch chrissx
        Es ist immer noch chrissx
    - name: Alarm
      artists:
        - chrissx
        - Mister Häfftling
      isrc: GX5MY2369887
      length: 1:08
      lyrics: |
        [Part]
        Yea ich sitze im lab
        Deine Gang ist immer noch ziemlich whack
        Eure Ehre nehme ich überigens auch
        Deine ganze Gang wird von Fux Games gekauft
        Ich droppe in den beat hinein, er ist super tight (pause)
        Und ich finde darin einen nicen reim
        Und du bist in etwa so klug wie ein Stein
        Niemand hat je deine Tweets geliket
        Ich sags nochmal, es ist schon bekannt
        Deine ganze Gang nur Husos verdammt
        Und ich sags dir immer wieder, es fuckt dich ab
        Keiner deiner jungs hat so viel balls wie ne tranny vom land
        chris x häffti, shoutouts an mein Bruder
        Wenn ich mit mein girls chille sieht das aus wie bei Hooters
        Naes Kind ne Katze, ich gib ihr Futter
        Ich bin da dogmatisch, wie der Tisch seiner Mutter

        [Hook]
        Meine Coolness hebt den Klimawandel auf
        Meine Hotness löst nen Feueralarm aus
        Das nich Geschwisterbehinderung, fick die Crowd
        Keine Geschwisterbehinderung, ich fick die Crowd
    - name: Immer Noch Live
      isrc: GX5MY2391078
      length: 1:51
      lyrics: |
        [Intro]
        Ich fick deine Gang und mach immer weiter
        chrissx on top keine Karriereleiter
        Du rappst für Gangster? Junge sei ma leiser
        Ich rapp' für depressive Transen, shoutout Lana Kaiser
        Ich wichse meine Schuhe und ich wichs in deine Schule
        Und ich fick wie eine Hure und ich ficke deine Bude
        Und ich mach's bis ich rich bin kein Broker
        Die bitch rockt nen Collar nein kein Choker
        Die ganze Nacht sexten wir auf Discord noch ganz extrem
        Und 15 Minuten später haben wir ein Problem
        Das war kein Subtweet trotz Bezug
        Aber diss mich noch paar mal öffentlich is ja gut

        [Part]
        Es ist 6 Uhr morgens ich bin wieder im Stu
        Ich sags auf jedem track aber es bleibt true
        Ich geh ins Bett wenn andere aufstehen
        Weil ich anfang mit Rap wenn andre ins Bett gehen
        Aber meine Homes immer im grünen Bereich wie der WhatsApp-Hörer
        Du bleibst ein Bastard wie Markus
        Du sagst 20 mal Deutschrap ist tot und geht
        Aber wohin denn junge solange Pegnitz lebt
        Mach was du willst, auch wenn du dir bars gibst
        Aber dein Stolzmonat outet dich als Nazi
        Du siehst aus wie ein Streber der gelernt hat den ganzen Tag lang
        Schwenk noch ne Deutschlandflagge du bleibst ein Bastard
        Ich stream immer noch live weil ich kann das gut
        Du lebst nicht in Sachsen warum boymodest du
        Ich hab Videos geschnitten, ihr Chemie gelernt
        Mein ganzes Leben im Internet, das nie verlernt (durchgefickt)
    - name: Rapperin
      isrc: GX5MY2382044
      length: 3:14
      lyrics: |
        [Part 1]
        Ich bleib ein OG in Peg, Häffti bleibt Drogendealer
        Yea es is chrissx, halbe Mona Lisa
        Ich manage den shit kein Ventoy
        Und von meinem cum ernähren sich Femboys
        Yo Mizeb du hast das schon richtig erkannt
        Rapbattle heißt wer hat den dickeren Arsch
        Und ja ich komme in deine Stadt
        Chill am Magnus-Hirschfeld-Platz
        Und ich muss weil jede andere ne Bottom ist
        Halt wieder hoes benutzen wie wenn Linus sein PC bricht
        Du wirst abgerippt keine CD, homes
        Und ich shoot auf dich kein Videodreh, homes
        Baddies kommen in die hood, RE 32
        Sie wollen mich sehen, ich bin fly und fly-sick/fleißig
        Das heißt dass ich einfach tight bin
        Und daily worke dafür dass ich lines spitt

        [Hook]
        Ich brauch keine echten Hits
        Solange alles Facts in den Texten sind
        Nicht eine die in der Schule die Beste is
        Ich war immer nur Rapperin
        Dein Homie sagt auch dass er häfftig is
        Lächerlich, ich glaub ihr checkt des nich
        Keine Tauis für nen fetten Ring
        Ich war immer nur Rapperin

        [Part 2]
        Du likest die Story wo ich sag: Keiner kommt klar mit mir
        Bei Fler is sowas ja gar ned real
        Bei mir schon ja ich sagt es dir
        Und paar Wochen später kommst du nicht mehr klar mit mir
        Und ich ficke AntiDs immer noch mit guten homes
        Das H in deinem Namen es steht für Hurensohn
        Und ich rapp nicht dass ich Leute auf der street box
        Weil alle meine Texte sind realtalk
        Und jeder zweite Lehrer hat mich mal gehasst
        Weil ich schon immer das Problemkind der Klasse war
        Hörte immer auf Mutter Natur und nicht auf Vater Staat
        Und das is auch der Grund warum ich keine Arbeit hab
        Rocke Pride-Flaggen, ich find dieses Bunte toll
        Die bitch is n Blauhai, sie is so wundervoll
        Alles gut, auch weil mein cum auf ihre Zunge soll
        Ich mache jede trans fem zu nem toy

        [Hook]
        Ich brauch keine echten Hits
        Solange alles Facts in den Texten sind
        Nicht die die in der Schule die Beste is
        Ich war immer nur Rapperin
        Dein Homie sagt auch dass er häfftig is
        Lächerlich, ich glaub ihr checkt des nich
        Keine Tauis für nen fetten Ring
        Ich war immer nur Rapperin

        [Bridge]
        chrissx Media, das kein Major
        Und alle alten Hater sind heute Chaser
        Und du meinst meine Texte wären zu verkopft
        Aber hast noch nie mit vollgewichstem Shirt Minecraft gezockt
        Acht Jahre YouTube, sieben Jahre Rap
        Ich sag der shit is mein Leben und das is kein cap
        Das is kein Flex, aber rein objektiv
        Is wenn du mich disst deine Sicht verzerrt, kein Objektiv (fisheye)

        [Hook]
        Ich brauch keine echten Hits
        Solange alles Facts in den Texten sind
        Nicht eine die in der Schule die Beste is
        Ich war immer nur Rapperin
        Dein Homie sagt auch dass er häfftig is
        Lächerlich, ich glaub ihr checkt des nich
        Keine Tauis für nen fetten Ring
        Ich war immer schon Rapperin
    - name: Älter
      isrc: GX5MY2392598
      length: 2:23
      lyrics: |
        [Part 1]
        Ja es geht mir besser ich hab den pain besiegt
        Oder mich zumindest so versteckt, dass er mich nicht kriegt
        Oder hab mich dran gewöhnt ich weiß es doch nich
        Trans sein is Trend, dann erklär warum du's nich bist
        Abi zwei-neun, weil ich konnte nix lernen
        Lag Monate im Bett und wollte nur sterben
        Heute cry ich nur wenn Freundinnen mich backstabben
        Oder gleich wegcanceln
        Immer kontrovers und bisschen hasse ichs
        Aber ein People Pleaser bin ich nicht
        Ich frag mich warum 1000 Subtweets dein Twitter ziern
        Es gibt keinen beef, du hast nur n Problem mit mir
        Hehe, meine Knie nicht da um sich zu beugen
        Mittlerweile daily am Freundinnen enttäuschen
        Ob bei mental health care oder Sex
        Aber immerhin lieg ich nich mehr den ganzen Tag im Bett
        Heute cry ich auch wenn ich Angst hab dass Freundinnen tot sind
        Weil das bei trannies halt manchmal so is
        Paar Schwestern sind gefallen, schon alles ok
        Aber auch klar dass es einem nicht immer gut geht

        [Hook]
        Ich werd
        Immer älter
        Immer älter
        Immer älter
        Ich werd
        Immer älter
        Immer älter
        Immer älter

        [Part 2]
        Die Raceplay-Situation, immer noch n wunder Punkt
        Da zieht sich n Rattenschwanz wie in den nudes von dem Hund
        Der Unterschied: Ich hab friends die schon richtige Wichser waren
        Ihr wollt euch nach nem Tweet schon nich mehr vertragen
        Ich hab viel dazu gelernt und jetz weiß ich mein Verhalten
        Und mein Umgang damit war zu jedem Zeitpunkt der falsche
        Ich hätte den Tweet löschen müssen und Jule sagen, dass sie sich ficken kann
        Die Sache ernst nehmen, mit den andern reden und dann
        Wären paar Leute noch mit mir die mich jetz hassen
        Aber alles ok man sollen se machen
        Raceplay orange in meinem KinkCheck
        Ich hab ne Nine in meinem Holster und ne Ten in meinem Bett

        [Hook]
        Ich werd
        Immer älter
        Immer älter
        Immer älter
        Ich werd
        Immer älter
        Immer älter
        Immer älter
